import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Voucher } from '../../../../../core/models/voucher.model';
import { GetDatasService } from '../../../../../core/services/get-datas.service';
import { TranslationService } from '../../../../../core/services/translation.service';
import { ErrorService } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-wallet-datas',
  templateUrl: './wallet-datas.component.html',
  styleUrls: ['./wallet-datas.component.scss'],
})
export class WalletDatasComponent implements OnInit {
  @Input() vouchers: Voucher[];
  @Output() triggerCall = new EventEmitter<string>();

  constructor(
    private getDatasService: GetDatasService,
    private translate: TranslationService,
    public error: ErrorService
  ) {}

  ngOnInit() {}

  copyValue(el: any, value: string) {
    navigator.clipboard.writeText(value).then(r => {});
    el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
    setTimeout(() => {
      el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
    }, 3000);
  }

  download(code: string, isUsed: boolean) {
    if (!isUsed) {
      this.getDatasService.getVoucher(this.translate.getContextId(), code).subscribe(res => {
        if (res) {
          const mediaType = 'application/pdf';
          const blob = new Blob([res], { type: mediaType });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      });
    }
  }
}
