import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';
// import { GoogleAnalyticService } from '../../../../../../core/services/google-analytic.service';
import { StorageService } from '../../../../../../core/services/storage.service';
import { PaymentService } from '../../../../../../core/services/payment.service';
import { CookieService } from 'ngx-cookie-service';
import { GetDatasService } from '../../../../../../core/services/get-datas.service';
import { ScreenService } from 'src/app/core/services/screen.service';
import { toDeliveryId } from '../../../../../../core/utils/enumToInt';
import { CheckoutOrValidationProgressionStep } from '../../../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CheckoutType } from '../../../../../../core/enums/checkout-type.enum';
import { ResourcesStore } from '../../../../../../stores/resources.store';
import { CartResponse, CartVoucherResponse, DeliveryTypeObject } from '@victoria-company/agora-client';
import { InternalDeliveryData } from '../../../../../../stores/chechout.store';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
  protected readonly DeliveryTypeObject = DeliveryTypeObject;
  protected readonly toDeliveryId = toDeliveryId;

  public resourcesStore = inject(ResourcesStore);

  //V2
  @Input() cart: CartResponse;
  @Input() internalDeliveryData: InternalDeliveryData;
  @Input() checkoutType: CheckoutType;
  @Input() step: CheckoutOrValidationProgressionStep;
  @Input() canDeleteVouchersAndWallet: boolean = true;
  @Input() isReceipt = false;
  @Input() hideProductList = false;
  @Input() isValidationTunnel = false;

  @Input() operationPending = false;
  @Input() showCTA = true;
  @Input() disableCTA = false;

  @Output() requestOpenDeliveryInfoModal = new EventEmitter();
  @Output() requestDeleteVoucher = new EventEmitter<CartVoucherResponse>();
  @Output() requestDeleteWallet = new EventEmitter<number>();
  @Output() requestValidateCurrentStep = new EventEmitter();
  @Output() requestGoToTheTop = new EventEmitter();

  get displayButton() {
    if (!this.cart) return false;
    else if (!this.cart.delegateId || this.step == CheckoutOrValidationProgressionStep.DELIVERY) return false;
    else if (this.screen.isDesktop || (this.screen.isTablet && this.screen.isLandscape)) return true;
    else if (this.screen.isMobile && this.step != CheckoutOrValidationProgressionStep.PAYMENT) return true;
    else return false;
  }

  constructor(
    public modal: ModalService,
    // private gA: GoogleAnalyticService,
    public storage: StorageService,
    public paymentService: PaymentService,
    public cookie: CookieService,
    public dataService: GetDatasService,
    public screen: ScreenService
  ) {}

  deleteVoucher(voucher: CartVoucherResponse) {
    this.requestDeleteVoucher.emit(voucher);
  }
  deleteWallet() {
    this.requestDeleteWallet.emit(0);
  }

  openDeliveryInfoModal() {
    this.requestOpenDeliveryInfoModal.emit();
    // this.store.dispatch(checkoutActions.openDeliveryInfoModal());
  }

  confirmStep() {
    this.requestValidateCurrentStep.emit();
  }

  goToTheTop() {
    this.requestGoToTheTop.emit();
  }

  hasBonusRemaining() {
    return this.cart.hostessBonus - this.cart.subTotal >= 0;
  }

  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly CheckoutType = CheckoutType;
}
