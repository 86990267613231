<div class="order-item-container {{ border }}" *ngIf="resources">
  <div class="orders-section">
    <div class="orders-section-item">
      <div class="order-inline">
        <div class="order-inline-inner">
          <div class="order-inline-thumb">
            <img loading="lazy" class="" alt="" data-ll-status="loaded" [src]="getDataService.getProductImagesPathForThumbs(order.orderItems[0]?.imageFilename)" />
          </div>
          <div class="order-inline-content">
            <div class="order-inline-line summary d-only">
              <div class="order-inline-date">
                <div class="label label_xlarge">{{ order.date | date: 'dd/MM/yyyy' }}</div>
              </div>
              <div class="order-inline-number">{{ resources.profile.orderLabel }}: {{ order.id }}</div>
              <div class="order-inline-price flex j-end flex-1">
                <div class="label label_medium">{{ order.totalToPay | agoraCurrency }}</div>
              </div>
            </div>
            <div class="order-inline-line summary flex column m-only">
              <div class="flex row j-space-between">
                <div class="order-inline-date">
                  <div class="label">{{ order.date | date: 'dd/MM/yyyy' }}</div>
                </div>
                <div class="order-inline-price flex j-end flex-1">
                  <div class="label label_medium">{{ order.totalToPay | agoraCurrency }}</div>
                </div>
              </div>
              <div class="order-inline-number">{{ resources.profile.orderLabel }}: {{ order.id }}</div>
            </div>
            <div class="order-inline-line">
              <div class="order-inline-label">
                <div class="label label_default">{{ resources.profileOrders.statusLabel }}:</div>
              </div>
              <div class="order-inline-status">
                <div class="badge badge_pink">
                  <p>{{ 'GLOBAL.ORDER.STATUS' + order.statusId | translate }}</p>
                </div>
              </div>
            </div>
            <div class="order-inline-line">
              <div class="order-inline-label">
                <div class="label label_default">{{ resources.profileOrders.itemsLabel }}:</div>
              </div>
              <div class="order-inline-items" *ngFor="let orderItem of order.orderItems; index as i">
                <span>{{ orderItem.name + (i + 1 === order.orderItems.length ? '' : ',') }}&nbsp;</span>
              </div>
            </div>
            <div *ngIf="order.demoCode" class="order-inline-line gap-10 flex ai-center">
              <div class="icon-party-order flex center-all">
                <svg viewBox="0 0 32 32" class="icon">
                  <use xlink:href="#icon-party"></use>
                </svg>
              </div>
              <span>{{ 'ORDER.VIEW.ORIGINE.DEMO' | translate }}</span>
            </div>
            <div class="order-inline-button">
              <a
                *ngIf="delivered"
                [routerLink]="[(actionLink ?? '/' + locale + '/auth/profile/orders/') + order.id]"
                [queryParams]="{ delivered }"
                role="button"
                class="button button_secondary button_large">
                {{ resources.profile.detailsButtonLabel }}
              </a>
              <a *ngIf="!delivered" [routerLink]="[(actionLink ?? '/' + locale + '/auth/profile/orders/') + order.id]" role="button" class="button button_secondary button_large">
                {{ resources.profile.detailsButtonLabel }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
