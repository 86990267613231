import { AlertPositions, AlertTypes } from '../enums/alert-type.enum';

export class Alert {
  public id: string = 'display-alert-toast';
  public message: string = '';
  public title: string = '';
  public urlLink: string = undefined;
  public icon: string = '#icon-alert-success';
  public type: AlertTypes | string = AlertTypes.INFO;
  public position: AlertPositions | string = AlertPositions.BOTTOM;
  public duration: number;
}
