<div class="modal-container" id="encoding-demo-code-modal" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal" *ngIf="resourcesStore.isLoaded()">
    <div class="modal-icon icon peach" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1>{{ resourcesStore.i18n().resources.shoppingBag.birthdayLabel }}</h1>

    <div class="modal-body promo">
      <p>{{ 'MODAL.PROMO.SUBTITLE' | translate }}</p>
      <div class="promo-form">
        <label>{{ 'MODAL.PROMO.LABEL' | translate }}</label>
        <div class="flex gap-8">
          <div [class.error]="error">
            <input type="text" [(ngModel)]="demoCode" [placeholder]="'MODAL.PROMO.PLACEHOLDER' | translate" />
            <label *ngIf="error">{{ error }}</label>
          </div>
          <button class="btn btn-primary" (click)="addCodeToDemo()">{{ 'GLOBAL.ACTION.ADD' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
