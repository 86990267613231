import { ProductVariantSearchModel } from '@victoria-company/agora-client';

import { DateOnly } from '@microsoft/kiota-abstractions';

export class ProductVariantVM {
  productVariantId: string;
  size: string;
  price: number;
  salesPrice: number;

  stock: number;
  additionnalStock: number;
  sku: string;
  eta: DateOnly;
  isLSC: boolean;

  public static create(data: ProductVariantSearchModel) {
    const vm = new ProductVariantVM();
    vm.productVariantId = data.id;
    vm.sku = data.sku;
    vm.size = data.size;
    vm.price = data.normalPrice;
    vm.salesPrice = data.currentPrice;
    vm.stock = data.stock;
    vm.additionnalStock = data.additionalStock;
    vm.eta = null;
    // vm.isLSC = data.isLsc;
    return vm;
  }
}
