import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, Output, inject } from '@angular/core';
import { FormsService } from 'src/app/core/services/forms.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { TranslationService } from '../../../core/services/translation.service';
import { Wallet } from '../../../core/models/wallet.model';
import { CardType } from '../../../core/enums/card-type.enum';
import { ActionsService } from '../../../core/services/actions.service';
import { TriggerService } from '../../../core/services/trigger.service';
import { Voucher } from '../../../core/models/voucher.model';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { createMask } from '@ngneat/input-mask';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataForm } from '../../../core/models/dataForm.model';
import { ClientSummary } from '../../../core/models/client-summary.model';
import { CookieService } from 'ngx-cookie-service';
import { DemoService } from '../../../features/agora-pages/demo/services/demo.service';
import { RoleEnum } from 'src/app/features/agora-pages/demo/enums/role.enum';
import { AgoraCurrency } from 'src/app/core/pipes/agoraCurrency.pipe';
import { TranslateService } from '@ngx-translate/core';
import { WishlistService } from '../../../core/services/V2/wishlist.service';
import { CartService } from '../../../core/services/V2/cart.service';
import { CartItemVM } from '../../../core/viewModels/cartVM.model';
import { WishlistItemVM } from '../../../core/viewModels/wishlistVM.model';
import { getProfilePictureOrDefault } from '../../../core/utils/filter.utils';
import { ResourcesStore } from '../../../stores/resources.store';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() title: string;
  @Input() titleNextStep: string;
  @Input() template: string;
  @Input() item: WishlistItemVM | CartItemVM | any;
  @Input() currentWallet: Wallet;
  @Input() width: any;
  @Input() modalClass: any;
  @Input() isWishlist = false;
  @Input() hiddeLink = false;
  @Input() isClosable = true;
  @Input() isDemo = false;
  @Input() clientGuid: string;
  @Input() demoCode: string;
  @Input() resolvedId: string;
  @Input() wishlistElement: any;
  @Input() cartId: string;

  @Output() updateBirthDate = new EventEmitter<any>();

  resourcesStore = inject(ResourcesStore)

  public get bForm() {
    return this.birthDateForm?.controls;
  }

  //internal
  viewNextStep = false;
  value: any;
  formData: any = {};

  articleName: string;
  articleSizes: any[] = [];
  articleSelected: any;

  hasError = false;
  hasFormError = false;
  errorCode = 0;
  resources: any;
  popUpWarranty: any;
  popUpOrder: any;
  footer: any;
  isLoading = false;
  cardType = CardType;
  voucherValue: any;
  voucherCreated: Voucher;
  dateInputMask: any;
  birthDateForm: UntypedFormGroup;
  summary: ClientSummary;
  locale: string;

  role: RoleEnum;

  @Input()
  selectedTab = 'localisation';

  //cartVM: Signal<CartVM> = toSignal(this.cartService.cart)
  // wishlistVM: Signal<WishlistVM> = toSignal(this.wishlistService.wishlist)

  constructor(
    public modal: ModalService,

    private translate: TranslationService,
    private translation: TranslateService,
    private jwtService: JWTService,
    private actionsService: ActionsService,
    private trigger: TriggerService,
    private getDatasService: GetDatasService,
    private fb: UntypedFormBuilder,
    private cookie: CookieService,
    public formService: FormsService,
    private demoService: DemoService,
    private agoraCurrency: AgoraCurrency,
    private wishlistService: WishlistService,
    private cartService: CartService
  ) {
    this.demoService.demo$.subscribe(demo => {
      if (demo) this.role = demo.accessLevel;
      else this.role = RoleEnum.CLIENT;
    });

    this.modal.hasError$.subscribe(value => {
      this.hasError = value;
    });

    this.modal.errorCode$.subscribe(value => {
      this.errorCode = value;
    });

    this.modal.reset$.subscribe(_ => {
      this.viewNextStep = false;
      this.hasError = false;
      this.hasFormError = false;
      this.value = undefined;
      this.formData = {};

      this.getDefaultCountry();

      if (this.template == 'size') {
        this.selectedTab = '0';
      }
      if (this.template == 'simpleSize') {
        this.selectedTab = '0';
      } else if (this.template == 'walletCreateVoucher') {
        this.selectedTab = 'createVoucherTab';
      } else {
        this.selectedTab = 'localisation';
      }
    });

    this.actionsService.createdVoucher$.subscribe(res => {
      if (res) {
        this.voucherCreated = res;
      }
    });

    this.trigger.resetDefaultValueAddVoucher$.subscribe(res => {
      if (res && this.currentWallet) {
        this.voucherValue = this.currentWallet.amount.toFixed(2); //(this.currentWallet.total - this.currentWallet.totalVouchers).toFixed(2);
      }
    });
  }

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    if (this.item) {
      this.articleSizes = [];
      this.articleName = this.item?.name ?? this.item?.productGroup?.name;

      this.item.articles?.forEach(x => {
        const stockInfo = { isRAL: false, hasStock: true }; //TODO this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
        const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
        const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
        this.articleSizes.push({
          id: x.productVariantId,
          value: x.size + ' - ' + this.agoraCurrency.transform(x.salesPrice) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''),
          stock: x.stock,
          canSelect: displaySalesButton,
        });
      });

      // if (this.item?.productGroup) {
      //   this.item?.productGroup?.products.forEach(x => {
      //     let stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
      //     let displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE)
      //       || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
      //     let showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT
      //
      //     this.articleSizes.push({ id: x.sku, value: x.size + ' - ' + this.agoraCurrency.transform(x.price) + (showRalMessage ? ' | '+ this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''), stock: x.stock, canSelect: displaySalesButton })
      //   });
      // } else if (this.item?.variants) {
      //   this.item?.variants?.forEach(x => {
      //     let stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
      //     let displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE)
      //       || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
      //     let showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT
      //
      //     this.articleSizes.push({ id: x.id, value: x.title + ' - '  + this.agoraCurrency.transform(x.price) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''), stock: x.stock, canSelect: displaySalesButton });
      //   });
      // }

      this.articleSelected = this.articleSizes?.find(x => x.canSelect)?.id;
    }

    this.getDefaultCountry();

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
        this.footer = this.translate.getJsonLocales()?.footer;
        this.popUpWarranty = this.translate.getJsonLocales()?.popUpWarranty;
        this.popUpOrder = this.translate.getJsonLocales()?.popUpOrder;
      }
    });

    console.log("RESOURCES : ", this.resources)

    this.dateInputMask = createMask<Date>({
      alias: 'datetime',
      inputFormat: 'dd/mm/yyyy',
      placeholder: '__/__/____',
      parser: (value: string) => {
        const values = value.split('/');
        const year = +values[2];
        const month = +values[1] - 1;
        const date = +values[0];
        return new Date(year, month, date);
      },
    });

    this.birthDateForm = this.formService.getBirthDateForm(this.fb, new DataForm());

    this.getDatasService.summary$.subscribe(res => {
      if (res) {
        this.summary = res;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.item && changes.item?.currentValue != undefined) {
      if (this.item) {
        this.articleSizes = [];
        this.articleName = this.item?.name ?? this.item?.productGroup?.name;

        this.item.articles?.forEach(x => {
          const stockInfo = { isRAL: false, hasStock: true }; //TODO this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
          const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
          const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
          this.articleSizes.push({
            id: x.productVariantId,
            value: x.size + ' - ' + this.agoraCurrency.transform(x.salesPrice) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''),
            stock: x.stock,
            canSelect: displaySalesButton,
          });
        });

        // if (this.item?.productGroup) {
        //   this.item?.productGroup?.products.forEach(x => {
        //     let stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
        //     let displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE)
        //       || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
        //     let showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT
        //
        //     this.articleSizes.push({ id: x.sku, value: x.size + ' - ' + this.agoraCurrency.transform(x.price) + (showRalMessage ? ' | '+ this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''), stock: x.stock, canSelect: displaySalesButton })
        //   });
        // } else if (this.item?.variants) {
        //   this.item?.variants?.forEach(x => {
        //     let stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
        //     let displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE)
        //       || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
        //     let showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT
        //
        //     this.articleSizes.push({ id: x.id, value: x.title + ' - '  + this.agoraCurrency.transform(x.price) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : ''), stock: x.stock, canSelect: displaySalesButton });
        //   });
        // }

        this.articleSelected = this.articleSizes?.find(x => x.canSelect)?.id;
      }
    }
  }

  getQuantity(quantity) {
    const stock = this.item.productGroup.products.find(x => x.sku == this.item.sku)?.stock;
    const max = stock >= 10 ? 10 : stock;
    const quantities = [];

    for (let i = 1; i <= max; i++) {
      quantities.push({ id: i, value: i });
    }

    if (max < quantity) {
      {
        quantities.push({ id: quantity, value: quantity });
      }
    }

    return quantities;
  }

  getDefaultCountry() {
    if (this.template == 'searchDelegate') {
      switch (this.translate.getContextId()) {
        case 1:
          this.formData.country = 'FR';
          break;
        case 2:
          this.formData.country = 'BE';
          break;
        case 3:
          this.formData.country = 'DE';
          break;
        default:
          this.formData.country = 'FR';
          break;
      }
    }
  }

  selectItem() {
    if (this.articleSelected) {
      // if (this.isDemo) {
      //   this.demoService.postOrderItem(this.articleSelected, 1, this.clientGuid, this.resolvedId, this.item.isInWishlist, this.item.sku);
      //   this.modal.close('#' + this.id);
      //   return;
      // }
      // this.orderService.postGuestOrderItem(this.articleSelected, this.item, false, this.item.isInWishlist, this.item.sku);

      const wishlistItem = []; //this.wishlistVM()?.items.find(i => i.variants.map(a => a.productVariantId).find(variantId => variantId == this.articleSelected));
      const articleItem = []; //wishlistItem?.variants.find(x => x.productVariantId == this.articleSelected);

      console.log('wishlistItem : ', wishlistItem);
      console.log('articleItem : ', articleItem);
      // console.log("Add item ", articleItem?.productVariantId, " to cart ", this.cartVM()?.cartId);

      // this.cartService.add(articleItem?.productVariantId, this.cartVM()?.cartId).pipe(
      //   switchMap(_ => from(Promise.resolve(this.wishlistService.delete(wishlistItem?.id)))),
      // ).subscribe(  _ => {
      //   this.cartService.get();
      // });

      this.modal.close('#' + this.id);
    }
  }

  private async deleteItem() {
    if (this.item instanceof CartItemVM) {
      console.log('item to delete : ', this.item);
      await this.cartService.delete(this.item.productVariantId, this.item.cartId);
      // .subscribe(
      //   res => {
      //     //this.cartService.getCart();
      //   },
      //   err => {
      //     console.log('Error deleteItem in modal');
      //   }
      // );
    }

    // if (!this.isDemo) {
    //   this.orderService.deleteGuestOrderItem(this.item.sku, this.item);
    // } else {
    //   if(this.wishlistElement) {
    //     this.demoService.deleteWishlistItem(this.item.sku, this.clientGuid, this.resolvedId);
    //   } else {
    //     this.demoService.deleteOrderItem(this.item.sku, this.clientGuid, this.resolvedId);
    //   }
    // }
  }

  public confirmChoice(value: boolean) {
    if (value == true) this.deleteItem();
    this.modal.close('#' + this.id);
  }

  switchTab(tab: string) {
    this.hasError = false;
    this.hasFormError = false;
    this.selectedTab = tab;
  }

  validateFormAndCheck() {
    if (this.formData && this.formData.country && (this.formData.city || this.formData.zip)) {
      this.modal.executeAction({ actionName: 'searchDelegateBy', value: this.formData });
      this.modal.close('#' + this.id);
    } else {
      this.hasFormError = true;
    }
  }

  validateDelegateAndCheck() {
    if (this.formData.name && this.formData.name.trim() != '') {
      this.modal.executeAction({ actionName: 'searchDelegateBy', value: this.formData });
      this.modal.close('#' + this.id);
    } else {
      this.hasError = true;
    }
  }

  validateDelegate(delegate) {
    this.modal.executeAction({ actionName: 'selectDelegate', value: delegate });
    this.modal.close('#' + this.id);
  }

  goNextStep() {
    this.viewNextStep = true;
  }

  stepBack() {
    this.viewNextStep = false;
  }

  createVoucherAction() {
    if (!this.voucherValue) {
      this.hasError = true;
      this.errorCode = 4;
      return;
    }
    this.isLoading = true;
    this.hasError = false;
    this.actionsService.postAddVoucher(this.jwtService.getSubFromToken(), this.voucherValue).subscribe(
      res => {
        if (res.data) {
          this.getDatasService.getSummary(this.jwtService.getSubFromToken());

          this.switchTab('voucherCreatedTab');
          this.actionsService.updateVoucher(res.data);
          this.trigger.refreshVouchersTable(true);
          this.isLoading = false;
        }
      },
      error => {
        this.isLoading = false;
        this.hasError = true;
        this.errorCode = error.error?.errors[0].type;
      }
    );
  }

  copyValue(el: any, value: string) {
    navigator.clipboard.writeText(value).then(r => {});
    el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
    setTimeout(() => {
      el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
    }, 3000);
  }

  closeCreatedVoucherModal() {
    this.modal.close('#walletCreateVoucher');
    this.selectedTab = 'createVoucherTab';
  }

  openCreationVoucherModal() {
    this.selectedTab = 'createVoucherTab';
    this.voucherValue = (this.currentWallet.total - this.currentWallet.totalVouchers).toFixed(2);
  }

  removeItemFromWishlist() {
    // this.removeItem(this.item);
    this.wishlistService.delete(this.item.id);
    this.modal.close('#' + this.id);
  }

  download(code: string) {
    this.getDatasService.getVoucher(this.translate.getContextId(), code).subscribe(res => {
      const mediaType = 'application/pdf';
      const blob = new Blob([res], { type: mediaType });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  addBirthDate(isMobile: boolean) {
    const datas = this.birthDateForm.getRawValue();
    if (false && isMobile) {
      const values = datas.birthDate.split('-');
      const year = +values[0];
      const month = +values[1] - 1;
      const date = +values[2];
      datas.birthDate = new Date(year, month, date);
    }

    if (datas.birthDate && this.birthDateForm.valid) {
      this.actionsService.postAddBirthDate(this.jwtService.getSubFromToken(), datas.birthDate).subscribe(res => {
        if (res) {
          this.updateBirthDate.emit(datas.birthDate);
          this.modal.close('#birthDateModal');
          this.modal.close('#myDatasBirthDateModal');
          this.getDatasService.getSummary(this.jwtService.getSubFromToken());
        }
      });
    } else this.formService.checkFormAndDisplayError(this.birthDateForm);
  }

  // private removeItem(item: any) {
  //   this.orderService.deleteWishlistItem(this.item.sku, this.item);
  // }
  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
