import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { AlertTypes } from 'src/app/core/enums/alert-type.enum';
import { Alert } from 'src/app/core/models/alert.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { FormsService } from 'src/app/core/services/forms.service';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-myacces',
  templateUrl: './myacces.component.html',
  styleUrls: ['./myacces.component.scss'],
})
export class MyaccesComponent implements OnInit {
  emailForm: UntypedFormGroup;
  requestPasswordStatus = 0;
  changeEmailStatus = 0;
  operationPending = false;
  locale: string;
  isDelegate: any;

  public get fEmail() {
    return this.emailForm?.controls;
  }

  constructor(
    private jwtService: JWTService,
    private cookie: CookieService,
    private fs: FormsService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private dataservices: GetDatasService,
    private alert: AlertService,
    private trad: TranslateService,
    private storage: StorageService,
    private authService: OidcSecurityService
  ) {}

  ngOnInit() {
    this.isDelegate = this.jwtService.isDelegate();

    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    this.emailForm = this.fs.getChangeEmailForms(this.fb, '');

    this.dataservices.clientData$.subscribe(res => {
      if (res) this.emailForm = this.fs.getChangeEmailForms(this.fb, res.email);
    });

    this.changeEmailStatus = 0;
    this.requestPasswordStatus = 0;
  }

  update() {
    if (this.emailForm.valid) {
      this.operationPending = true;
      this.changeEmailStatus = 0;

      this.userService.postChangeUserEmail({ email: this.emailForm.get('email').value, newemail: this.emailForm.get('newemail').value }).subscribe(
        (res: any) => {
          this.operationPending = false;

          const alertData = new Alert();
          alertData.message = this.trad.instant('ALERT.EMAIL.UPDATE');
          alertData.type = AlertTypes.SUCCESS;

          this.alert.raiseAlert(alertData);

          if (res.data) {
            this.dataservices.updateClientDataInternaly(res.data);
            this.storage.redirectTo = '/' + this.locale + '/club/profile/access';
            this.authService.logoff();
            this.jwtService.deleteTokenCookie();
          }
        },
        err => {
          this.operationPending = false;

          const alertData = new Alert();
          alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
          alertData.type = AlertTypes.DANGER;
          alertData.icon = '#icon-warning';

          this.alert.raiseAlert(alertData);
        }
      );
    } else this.fs.checkFormAndDisplayError(this.emailForm);
  }

  requestNewPassword() {
    this.operationPending = true;
    this.requestPasswordStatus = 1;
    this.userService.postRequestNewPassword().subscribe(
      (res: any) => {
        const alertData = new Alert();
        alertData.message = this.trad.instant('ALERT.PASSWORD.REQUESTED');
        alertData.type = AlertTypes.SUCCESS;

        this.alert.raiseAlert(alertData);
        this.operationPending = false;
      },
      err => {
        const alertData = new Alert();
        alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
        alertData.type = AlertTypes.DANGER;
        alertData.icon = '#icon-warning';

        this.alert.raiseAlert(alertData);
        this.operationPending = false;
      }
    );
  }
}
