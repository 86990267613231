import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ClientOrderItem } from '../../../core/models/clientOrderItem.model';
import { TranslationService } from '../../../core/services/translation.service';
import { filterURL, getProfilePictureOrDefault, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { StorageService } from '../../../core/services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { ClientOrder } from '../../../core/models/clientOrder.model';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @ViewChild('orderArticleDescription', { static: true })
  protected orderArticleDescription: TemplateRef<any>;

  @Input() order: ClientOrder;
  @Input() displayBackToOrders: { title: string; link: string };
  @Input() orderItems: ClientOrderItem[];
  resources: any;
  footer: any;
  locale: string;

  constructor(
    private translate: TranslationService,
    private storage: StorageService,
    public cookie: CookieService
  ) {}

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');
    this.translate.localesLoaded.subscribe(res => {
      if (res === true) {
        this.resources = this.translate.getJsonLocales()?.resources;
        this.footer = this.translate.getJsonLocales()?.footer;
      }
    });
  }

  hasOnlyGift() {
    return this.order.orderItems.filter(x => x.sku.startsWith('EGIFT')).length == this.order.orderItemsCount;
  }

  public filter(url) {
    return getVictoriaUrl(this.storage.languageCode, true) + filterURL(url);
  }

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
