import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Voucher } from '../../../../../core/models/voucher.model';
import { LoaderService } from '../../../../../core/services/loader.service';
import { DatatableService } from '../../../../../core/services/datatable.service';
import { TriggerService } from '../../../../../core/services/trigger.service';
import { JWTService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-wallet-data-wrapper',
  templateUrl: './wallet-data-wrapper.component.html',
  styleUrls: ['./wallet-data-wrapper.component.scss'],
})
export class WalletDataWrapperComponent implements OnInit {
  formFilter: UntypedFormGroup;
  pageIndex = 0;
  hasMore: boolean;
  vouchers: Voucher[];
  loaded = false;
  firstCall = false;
  loadedMore = false;

  constructor(
    public datatableService: DatatableService,
    private jwtService: JWTService,
    private formBuilder: UntypedFormBuilder,
    private loader: LoaderService,
    private trigger: TriggerService
  ) {
    this.loader.loaded$.subscribe(res => {
      if (this.firstCall) {
        this.loadedMore = false;
      }
      if (!this.firstCall) {
        this.firstCall = true;
      }
      this.loaded = res;
    });
  }

  ngOnInit() {
    this.datatableService.voucherDatas$.subscribe(data => {
      if (this.pageIndex == 0) this.vouchers = data;
      else this.vouchers.push(...data);
    });

    this.formFilter = this.formBuilder.group({
      showUsed: [true],
    });

    this.formFilter.controls.showUsed.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.loadedMore = true;
      this._getValues();
    });

    this.trigger.refreshVouchersTable$.subscribe(res => {
      if (res) {
        this._resetDatatable();
      }
    });

    this._getValues();
  }

  getMoreDatas() {
    if (!this.loadedMore) {
      this.pageIndex += 1;
      this.loadedMore = true;
      this._getValues();
    }
  }

  private _getValues() {
    this.datatableService.getVouchers(this.jwtService.getSubFromToken(), this.formFilter.getRawValue(), this.pageIndex, true);
  }

  private _resetDatatable() {
    this.pageIndex = 0;
    this._getValues();
  }
}
