import { Component, OnInit } from '@angular/core';
import { GetDatasService } from '../../../../core/services/get-datas.service';
import { NavigatorShareService } from 'src/app/core/services/navigator-share.service';

@Component({
  selector: 'app-client-code',
  templateUrl: './client-code.component.html',
  styleUrls: ['./client-code.component.scss'],
})
export class ClientCodeComponent implements OnInit {
  codeClient: string;

  constructor(
    private navigatorShare: NavigatorShareService,
    private getDatasService: GetDatasService
  ) {
    this.getDatasService.clientData$.subscribe(res => {
      if (res) {
        this.codeClient = res.clientCode;
      }
    });
  }

  ngOnInit() {}

  share() {
    if (!this.navigatorShare.canShare()) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.navigatorShare
      .share({
        title: 'URL',
        url: this.codeClient,
      })
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
  }
}
