<app-page-title mainTitle="{{ 'WALLET.TITLE' | translate }}" subTitle="{{ 'WALLET.SUBTITLE' | translate }}"> </app-page-title>

<div class="order-1 center wallet-description" *ngIf="currentWallet">
  <p innerHTML="{{ 'WALLET.RESUME.AMOUNTX' | translate: { X: currentWallet.amount | agoraCurrency } }}"></p>
  <p class="resume">{{ 'WALLET.RESUME.DESCRIPTION' | translate }}</p>
</div>

<div class="wallet-summary-container flex row flex-1 j-start ai-center">
  <div class="wallet-status-card" *ngIf="currentWallet">
    <app-card
      class="wallet-status-card"
      [data]="currentWallet"
      [cardType]="cardType.WALLET"
      [showTooltip]="true"
      [footerInformationTooltipText]="'WALLET.TOOLTIP' | translate"
      [footerInformationTooltipPosition]="'right'"
      [paddingBottom]="!!currentWallet.hasAlertExpirationVoucher"></app-card>
  </div>

  <div class="order-1 center wallet-description">
    <p class="wallet-description-resume">{{ 'WALLET.RESUME.DESCRIPTION' | translate }}</p>
  </div>

  <div class="wallet-description flex-3 flex column left text" *ngIf="currentWallet">
    <div class="">
      <div class="order-1 ac-center resume">
        <p innerHTML="{{ 'WALLET.RESUME.AMOUNTX' | translate: { X: currentWallet.amount | agoraCurrency } }}"></p>
        <p>{{ 'WALLET.RESUME.DESCRIPTION' | translate }}</p>
      </div>
      <div class="wallet-advantages order-3">
        <span class="advantages-title">{{ 'WALLET.RESUME.AVANTAGES.TITLE' | translate }}</span>
        <ul class="advantages-list" innerHTML="{{ 'WALLET.RESUME.AVANTAGES.LIST' | translate }}"></ul>
      </div>
    </div>
  </div>
</div>

<div class="wallet-create-gift-container flex j-center" *ngIf="currentWallet && currentWallet.total > 0">
  <div class="center-section create flex">
    <div>
      <h2 class="box-title">{{ 'WALLET.CREATEVOUCHER.TITLE' | translate }}</h2>
      <p innerHTML="{{ 'WALLET.CREATEVOUCHER.LABEL' | translate }}"></p>
      <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all" (click)="displayCreationDialogAction()">
        <div class="label uppercase">{{ 'WALLET.CREATEVOUCHER.ACTION' | translate }}</div>
      </button>
    </div>
  </div>
</div>

<div class="my-gift">
  <app-wallet-data-wrapper></app-wallet-data-wrapper>
</div>
<div class="wallet-action">
  <app-wavedlink [linkUrl]="'/' + locale + '/club/point-encoding'" [fragmentUrl]="'action-history'" linkText="{{ 'WALLET.VOUCHER.ACTION.TOPOINTENCODING' | translate }}"></app-wavedlink>
</div>

<app-modal id="walletCreateVoucher" [currentWallet]="currentWallet" selectedTab="createVoucherTab" template="walletCreateVoucher" width="112rem" modalClass="walletCreateVoucher"></app-modal>
