import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DelegateProfile } from '../../../../../core/types/size-dropdown.type';
import { getProfilePictureOrDefault } from '../../../../../core/utils/filter.utils';
import { ResourcesStore } from '../../../../../stores/resources.store';

@Component({
  selector: 'app-select-delegate-modal',
  templateUrl: './select-delegate-modal.component.html',
  styleUrls: ['./select-delegate-modal.component.scss'],
})
export class SelectDelegateModalComponent {
  public resourcesStore = inject(ResourcesStore);

  @Input() isOpened: boolean;
  @Input() delegates: DelegateProfile[] = [];
  @Output() requestSelectDelegate = new EventEmitter<DelegateProfile>();
  @Output() requestCloseSelectDelegateModal = new EventEmitter();

  constructor() {}

  selectDelegate(delegate: DelegateProfile) {
    this.requestSelectDelegate.emit(delegate);
  }
  close() {
    this.requestCloseSelectDelegateModal.emit();
  }

  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
