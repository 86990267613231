import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from '../../../core/services/translation.service';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { ClientOrder } from '../../../core/models/clientOrder.model';

@Component({
  selector: 'app-order-short',
  templateUrl: './order-short.component.html',
  styleUrls: ['./order-short.component.scss'],
})
export class OrderShortComponent implements OnInit {
  @Input() order: ClientOrder;
  @Input() border: string;
  @Input() actionLink: string = undefined;
  @Input() delivered: boolean = false;
  resources: any;
  locale: string;

  constructor(
    private translate: TranslationService,
    private cookie: CookieService,
    public getDataService: GetDatasService
  ) {}

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }
}
