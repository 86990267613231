import { Component, inject, OnInit } from '@angular/core';
import { ClientSummary } from '../../../../core/models/client-summary.model';
import { Subject } from 'rxjs';
import { GetDatasService } from '../../../../core/services/get-datas.service';
import { CardType } from '../../../../core/enums/card-type.enum';
import { JWTService } from 'src/app/core/services/jwt.service';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomeComponent implements OnInit {
  userStore = inject(UserStore);
  contextStore = inject(ContextStore);

  currentClientSummary: ClientSummary;
  cardType = CardType;

  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    private getDatasService: GetDatasService,
    public jwtService: JWTService
  ) {

  }

  ngOnInit() {
    if(this.userStore.isAuthenticated && !this.userStore.userCode()) this.userStore.loadUserCode()

    this.getDatasService.summary$.subscribe(data => {
      if (data) {
        this.currentClientSummary = data;
        this.eventsSubject.next();
      }
    });

  }
}
