<div class="status-datas-container">
  <div class="error-box" *ngIf="error.clientActionsError$ | async">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{ 'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="status && clientActions && clientActionsDone && (error.clientActionsError$ | async) == false">
    <div class="d-only">
      <table *ngIf="datas">
        <tr *ngFor="let action of datas">
          <td class="image">
            <img
              *ngIf="action.clientActionTypeId != 10 && action.clientActionTypeId != 11 && action.clientActionTypeId != 220"
              loading="lazy"
              src="assets/images/tips-{{ action.clientActionTypeId }}.png" />
            <svg *ngIf="action.clientActionTypeId == 11">
              <use xlink:href="#card-fb"></use>
            </svg>
            <svg *ngIf="action.clientActionTypeId == 10">
              <use xlink:href="#card-ig"></use>
            </svg>
            <svg *ngIf="action.clientActionTypeId == 220">
              <use xlink:href="#icon-group"></use>
            </svg>
          </td>
          <td class="description">
            <h3>{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.TITLE' | translate }}</h3>
            <p>{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.LABELXPOINTS' | translate: { X: action.points } }}</p>
          </td>
          <td class="received">
            <div class="flex j-center">
              <h2 *ngIf="action.displayPatternId != 3">{{ (action.displayPatternId === 1 ? 'GLOBAL.ADDXPOINTS' : 'GLOBAL.ADDONEPOINTEURO') | translate: { X: action.points } }}</h2>
              <h2 *ngIf="action.displayPatternId == 3">
                {{ 'GLOBAL.ADDXPOINTS' | translate: { X: 250 } }} <br /><br />
                {{ 'GLOBAL.ADDXEUROJEWELS' | translate: { X: 100 | agoraCurrencyInt } }}
              </h2>
            </div>
          </td>
          <td class="action-button">
            <div *ngIf="action.realisationDate">
              <svg viewBox="0 0 32 32" class="icon peach">
                <use xlink:href="#icon-checkmark"></use>
              </svg>
              &nbsp; {{ 'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: { X: action.realisationDate | date: 'dd/MM/yyyy' } }}
            </div>
            <div *ngIf="!action.realisationDate">
              <a [href]="getActionLink(action.type)" *ngIf="action.type !== 12 && action.type !== 11 && action.type !== 10 && action.type !== 13 && action.type != 220">
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin">
                  <div [ngSwitch]="action.type">
                    <label *ngSwitchCase="1">{{ 'STATUS.MOREPOINTS.BUY.ACTION' | translate }}</label>
                  </div>
                </button>
              </a>
              <div *ngIf="action.type === 12 || action.type === 11 || action.type === 10">
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin" (click)="sendAction(action)">
                  <div [ngSwitch]="action.type">
                    <label *ngSwitchCase="12">{{ 'STATUS.MOREPOINTS.NEWSLETTER.ACTION' | translate }}</label>
                    <label *ngSwitchCase="11">{{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}</label>
                    <label *ngSwitchCase="10">{{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}</label>
                  </div>
                </button>
              </div>
              <div *ngIf="action.type === 220">
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin" [routerLink]="'/' + locale + '/club/booster'">
                  <label>{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.ACTION' | translate }}</label>
                </button>
              </div>
              <div (click)="openBirthDateModal()" *ngIf="action.type === 13">
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin">
                  <label>{{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}</label>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="m-only">
      <app-carousel class="m-only carousel" *ngIf="datas">
        <div class="flex" *ngFor="let action of datas">
          <div class="card center flex-1">
            <div class="header">
              <img
                *ngIf="action.clientActionTypeId != 10 && action.clientActionTypeId != 11 && action.clientActionTypeId != 220"
                loading="lazy"
                src="assets/images/tips-{{ action.clientActionTypeId }}.png"
                alt="img" />
              <svg *ngIf="action.clientActionTypeId == 11">
                <use xlink:href="#card-fb"></use>
              </svg>
              <svg *ngIf="action.clientActionTypeId == 10">
                <use xlink:href="#card-ig"></use>
              </svg>
              <svg *ngIf="action.clientActionTypeId == 220">
                <use xlink:href="#icon-group"></use>
              </svg>
            </div>
            <div class="body">
              <div class="container">
                <p class="title">{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.TITLE' | translate }}</p>
                <p class="description">{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.LABELXPOINTS' | translate: { X: action.points } }}</p>
                <p class="received" *ngIf="action.displayPatternId != 3">
                  {{ action.displayPatternId === 1 ? ('GLOBAL.ADDXPOINTS' | translate: { X: action.points }) : ('GLOBAL.ADDONEPOINTEURO' | translate) }}
                </p>
                <p class="received" *ngIf="action.displayPatternId == 3">
                  {{ 'GLOBAL.ADDXPOINTS' | translate: { X: 250 } }} <br /><br />
                  {{ 'GLOBAL.ADDXEUROJEWELS' | translate: { X: 100 | agoraCurrencyInt } }}
                </p>
              </div>
            </div>
            <div class="footer">
              <div *ngIf="action.realisationDate" class="flex actionDone">
                <svg viewBox="0 0 32 32" class="icon peach height100">
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
                &nbsp; {{ 'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: { X: action.realisationDate | date: 'dd/MM/yyyy' } }}
              </div>
              <div *ngIf="!action.realisationDate">
                <a [href]="getActionLink(action.type)" *ngIf="action.type !== 12 && action.type !== 11 && action.type !== 10 && action.type !== 13">
                  <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all">
                    <div [ngSwitch]="action.type">
                      <label *ngSwitchCase="1">{{ 'STATUS.MOREPOINTS.BUY.ACTION' | translate }}</label>
                      <label *ngSwitchCase="220">{{ 'STATUS.MOREPOINTS.' + action.description.toUpperCase() + '.ACTION' | translate }}</label>
                      <label *ngSwitchCase="13">{{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}</label>
                    </div>
                  </button>
                </a>
                <div *ngIf="action.type === 12 || action.type === 11 || action.type === 10">
                  <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all" (click)="sendAction(action)">
                    <div [ngSwitch]="action.type">
                      <label *ngSwitchCase="12">{{ 'STATUS.MOREPOINTS.NEWSLETTER.ACTION' | translate }}</label>
                      <label *ngSwitchCase="11">{{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}</label>
                      <label *ngSwitchCase="10">{{ 'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate }}</label>
                    </div>
                  </button>
                </div>
                <div (click)="openBirthDateModal()" *ngIf="action.type === 13">
                  <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin flex center-all">
                    <label>{{ 'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate }}</label>
                  </button>
                </div>
              </div>
              <!--&lt;!&ndash; {{ca.realistionDate}} &ndash;&gt;
              <div *ngIf="!checkIfDone(ca.clientActionTypeId)">
                <button class="btn btn-primary m-j-center m-fit-content block m-auto-no-margin">
                  <div [ngSwitch]="ca.clientActionTypeId">
                    <div class="label" *ngSwitchCase="1">{{'STATUS.MOREPOINTS.BUY.ACTION' | translate}}</div>
                    <div class="label" *ngSwitchCase="11">{{'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate}}</div>
                    <div class="label" *ngSwitchCase="10">{{'STATUS.MOREPOINTS.SOCIAL.ACTION' | translate}}</div>
                    <div class="label" *ngSwitchCase="12">{{'STATUS.MOREPOINTS.NEWSLETTER.ACTION' | translate}}</div>
                    <div class="label" *ngSwitchCase="13">{{'STATUS.MOREPOINTS.BIRTHDAY.ACTION' | translate}}</div>
                  </div>
                </button>
              </div>
              <div *ngIf="checkIfDone(ca.clientActionTypeId)">
                <div class="flex row j-center done">
                  <svg viewBox="0 0 32 32" class="icon peach">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg> &nbsp; {{'STATUS.MOREPOINTS.ACTION.DONEATDATEX' | translate: {'X':ca.realisationDate} }}
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </app-carousel>
    </div>
  </div>
</div>

<app-modal [id]="'birthDateModal'" template="birthDateModal" modalClass="birthDateModal"></app-modal>
