<div class="manage-document-card-container">
    <div class="header">
      <h1 class="title">{{document.title}}</h1>
      <div class="actions" *ngIf="screen.isMobile">
          <svg class="icon" viewBox="0 0 24 24">
            <use xlink:href="#icon-menu-elipsis" />
          </svg>
      </div>
      <div class="actions flex gap-15" *ngIf="screen.isDesktop">
          <button class="secondary" [routerLink]="['edit/'+document.id]">
            <svg>
              <use xlink:href="#icon-edit-adress" />
            </svg>
            Modifier
          </button>
          <button class="primary" (click)="delete()">
            <svg>
              <use xlink:href="#icon-trash" />
            </svg>
            Effacer
          </button>
      </div>
    </div>
    <div class="body">
      <div class="info-container">
        <span [class]="'action action-type-' + document.type.toLowerCase()">{{document.type}}</span>
        <div *ngIf="contextStore.contextId()==2" class="language">{{ getLocaleDisplayFromLocale() }}</div>
      </div>
      <div class="details-container">
        <div class="dates-container">
          <div class="action-date" *ngIf="document.category == DocumentCategoryObject.CommercialAction">
            <svg class="icon">
              <use xlink:href="#icon-calendar" />
            </svg>
            Action du {{document.appliesFrom | date: "dd-MM-YY"}} au {{document.appliesUntil | date: "dd-MM-YY"}}
          </div>
          <div class="visibility-date">
            <svg class="icon">
              <use xlink:href="#icon-eye" />
            </svg>
            Visible du {{document.visibleFrom | date:"dd-MM-YY"}} au {{document.visibleUntil | date: "dd-MM-YY"}}
          </div>
        </div>
        <div class="attachments-container">
          <svg class="icon grey" [class.peach]="document.imageUrl">
            <use xlink:href="#icon-picture" />
          </svg>
          <svg class="icon grey" [class.peach]="document.link">
            <use xlink:href="#icon-link" />
          </svg>
          <svg class="icon grey" [class.peach]="document.attachments?.length">
            <use xlink:href="#icon-attachment" />
          </svg>
        </div>
      </div>
    </div>
</div>
