import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ErrorService } from 'src/app/core/services/error.service';

@Component({
  selector: 'app-waiting-orders-list-datas',
  templateUrl: './waiting-orders-list-datas.component.html',
  styleUrls: ['./waiting-orders-list-datas.component.scss'],
})
export class WaitingOrdersListDatasComponent implements OnInit {
  @Input() waitingOrders: any[];
  locale: string;

  constructor(
    private router: Router,
    private cookies: CookieService,
    public error: ErrorService
  ) {}

  ngOnInit() {
    this.locale = this.cookies.get('v_locale')?.replace('_', '-');
  }

  navigate(id: number) {
    this.router.navigate(['order', id], { replaceUrl: true });
  }
}
