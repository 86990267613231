<app-page-title mainTitle="{{ 'ORDERLIST.TITLE' | translate }}" subTitle="{{ 'ORDERLIST.SUBTITLE' | translate }}"> </app-page-title>

<!--<div class="orders-info-bulle flex row ai-center ac-center j-space-around">
  <div class="flex center-all">
    <div class="icon rounded icon-container flex center-all">
      <svg viewBox="0 0 36 36">
        <use xlink:href="#icon-warning"></use>
      </svg>
    </div>
    <p>
      {{'ORDERLIST.TOOLTIP'| translate}}
    </p>
  </div>
</div>-->

<div class="waiting-orders">
  <app-waiting-orders-list-data-wrapper></app-waiting-orders-list-data-wrapper>
</div>
<div class="delivered-orders">
  <app-delivered-orders-list-data-wrapper></app-delivered-orders-list-data-wrapper>
</div>
