import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../../../../core/services/order.service';
import { TranslationService } from '../../../../../core/services/translation.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
})
export class OrdersListComponent implements OnInit {
  constructor(
    public orderService: OrderService,
    private translate: TranslationService
  ) {}

  ngOnInit() {
    this.orderService.getOrders(this.translate.getContextId(), 0, true);
  }
}
