/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JWTService } from './jwt.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Voucher } from '../models/voucher.model';
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  //Add voucher
  private _createdVoucher$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public createdVoucher$: Observable<any> = this._createdVoucher$.asObservable();

  constructor(
    private http: HttpClient,
    private jwt: JWTService
  ) {}

  //Add code
  public postAddCode(clientSub: number, pointCode: string): Observable<any> {
    return this.http.post(BASE_URL + `/clients/${clientSub}/actions/addpoint`, { pointCode }, { withCredentials: true, headers: { Authorization: `Bearer ${this.jwt.getToken()}` } });
  }

  //Add voucher
  public postAddVoucher(clientSub: number, voucherValue: number): Observable<any> {
    return this.http.post(BASE_URL + `/clients/${clientSub}/vouchers`, { amount: voucherValue }, { withCredentials: true, headers: { Authorization: `Bearer ${this.jwt.getToken()}` } });
  }

  public updateVoucher(voucherValue: Voucher) {
    this._createdVoucher$.next(voucherValue);
  }

  public postAddAction(clientSub: number, action: string): Observable<any> {
    // this.headers = new HttpHeaders();
    // this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getSSOJWT());

    return this.http.post(BASE_URL + `/clients/${clientSub}/actions/onetime/${action}`, {}, { withCredentials: true, headers: { Authorization: `Bearer ${this.jwt.getToken()}` } });
  }

  public postAddBirthDate(clientSub: number, birthDate: Date): Observable<any> {
    // this.headers = new HttpHeaders();
    // this.headers = this.headers.append("Authorization", "Bearer " + this.jwt.getSSOJWT());

    return this.http.post(
      BASE_URL + `/clients/${clientSub}/actions/onetime/add_birthday`,
      { birthdayDate: birthDate },
      { withCredentials: true, headers: { Authorization: `Bearer ${this.jwt.getToken()}` } }
    );
  }
}
