<div class="dropzone-container" [ngClass]="classes">
  <input type="file" (change)="uploadedFileChanged($event)" [multiple]="allowMultiple" hidden #fileInput />
  <div class="dropzone" [style]="style" (click)="dropzoneClicked($event)" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
    <span *ngIf="dropzoneText && (!droppedFile && !attachmentSrc)">{{ dropzoneText }}</span>
    <span *ngIf="!dropzoneText && (!droppedFile && !attachmentSrc)">>Drop a file to download or click in the box</span>
    <div class="file-container" *ngIf="droppedFile || attachmentSrc || attachmentName">
      <div class="preview" [class.image]="filePreview || attachmentSrc">
        <svg class="icon delete" (click)="deleteFile($event)">
          <use xlink:href="#icon-close" />
        </svg>
        <img *ngIf="filePreview || attachmentSrc" [src]="filePreview??attachmentSrc" alt="image preview" />
      </div>
      <label *ngIf="droppedFile || attachmentName">{{ droppedFile?.name ?? attachmentName }}</label>
    </div>
  </div>
</div>
