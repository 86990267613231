import { Component, computed, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsStore } from '../../../../../../stores/documents.store';
import { DocumentCategoryObject, DocumentResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../../stores/context.store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-documents-listing',
  templateUrl: './documents-listing.component.html',
  styleUrls: ['./documents-listing.component.scss'],
})
export class DocumentsListingComponent implements OnInit {
  readonly active = inject(ActivatedRoute);
  readonly documentsStore = inject(DocumentsStore);
  readonly contextStore = inject(ContextStore);
  readonly router = inject(Router);
  readonly translate = inject(TranslateService)

  category: string = DocumentCategoryObject.CommercialAction.toUpperCase();
  documents = computed(() => {
    switch (this.category) {
      case DocumentCategoryObject.CommercialAction.toUpperCase():
        return this.documentsStore.commercialActionsForStaff();
      case DocumentCategoryObject.HostessGuide.toUpperCase():
        return this.documentsStore.hostessActionsForStaff();
    }
  });

  //MODAL
  deleteConfirmationModalOpened = false;
  documentToDelete:DocumentResponse;

  constructor() {}

  async ngOnInit() {
    this.active.params.subscribe(params => {
      if (params.category) this.category = params.category.toUpperCase();
    });

    if (!this.documentsStore.staffDocuments()) await this.documentsStore.loadDocumentsForStaff();
  }

  openDeleteDocumentModal(document:DocumentResponse){
    this.documentToDelete = document;
    this.deleteConfirmationModalOpened = true;
  }

  closeDeleteDocumentModal(){
    this.documentToDelete = undefined;
    this.deleteConfirmationModalOpened = false;
  }

  async deleteDocument(){
    await this.documentsStore.deleteDocumentById(this.documentToDelete?.id);
    this.closeDeleteDocumentModal()
  }

  async navigateTo(action: string, id?: string) {
    let route = `/${this.contextStore.locale()}/club/manage/${this.category.toLowerCase()}`;
    // if(id) route += `/${id}`;

    await this.router.navigate([route, action]);
  }

  getDeleteDocumentMessage(){
      this.translate.instant("GLOBAL.YES")
      return `Êtes vous sur de vouloir supprimer le document "${this.documentToDelete?.title}" ?`
  }
}
