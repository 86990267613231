<div class="status-card">
  <div class="header">
    <div class="card-title flex column ac-center ai-center">
      <span class="status-text">{{ 'GLOBAL.YOURSTATUS' | translate }}</span>
      <span class="status-value uppercase">{{ 'CLIENT.STATUS' + statusData.statusId | translate | uppercase }}</span>
    </div>
  </div>
  <div class="body" [ngClass]="summary?.status.hasAlertExpirationPoint ? 'paddingBottom' : ''">
    <div class="image">
      <div class="card-img-container flex center-all">
        <img loading="lazy" src="assets/images/business/statut-{{ statusData.statusId }}.svg" />
      </div>
    </div>
    <div class="content">
      <div class="full-height">
        <div class="datas {{ paddingBottom ? 'paddingBottom' : '' }} {{ dropPadding ? 'dropPadding-bottom' : '' }}">
          <div class="card-info flex column ac-center ai-center">
            <div class="info-main">
              <div class="info-main flex column ac-center ai-center total-points" [ngClass]="!actionDate ? 'pad-top' : ''">
                {{ 'GLOBAL.XPOINTS' | translate: { Points: statusData.totalPoints } }}
              </div>
            </div>
          </div>
        </div>
        <div class="actions" *ngIf="showStatusLink">
          <div class="card-info flex column ac-center ai-center">
            <div class="card-action">
              <app-wavedlink [linkUrl]="'/' + locale + '/club/status'" linkText="{{ 'GLOBAL.ADVANTAGES' | translate }}"></app-wavedlink>
              <!-- <a class="link-wave" [routerLink]="['/status']">
                <div class="flex row link">
                  {{'GLOBAL.ADVANTAGES' | translate}}
                  <svg viewBox="0 0 32 32">
                    <use xlink:href="#icon-arrow-right-long"></use>
                  </svg>
                </div>
                <svg class="line" viewBox="0 0 82 4" preserveAspectRatio="none">
                  <use xlink:href="#icon-line"></use>
                </svg>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="footer">
    <div class="card-tail pink flex column center-all pointer" *ngIf="summary?.status.hasAlertExpirationPoint && !hiddeFooterAction"  [routerLink]="['/' + locale + '/club/point-evolution']">
      <div class="info-main footer-title">
        {{'GLOBAL.ACTIONREQUIRED' | translate}}
      </div>
      <div class="info-sm ac-center ai-center flex footer-subtitle">
        <app-tooltip
          *ngIf="showTooltip"
          [text]="footerInformationTooltipText"
          [position]="footerInformationTooltipPosition"
          [width]="footerInformationTooltipWidth"
          [height]="footerInformationTooltipHeight"
        >
          <div>
            {{'GLOBAL.BEFOREDATEX' | translate : {'Date': summary.status.alertExpirationPointDate | date:'dd/MM/yyyy'} }}
          </div>
        </app-tooltip>
        <div *ngIf="!showTooltip">
          {{'GLOBAL.BEFOREDATEX' | translate : {'Date': summary.status.alertExpirationPointDate | date:'dd/MM/yyyy'} }}
        </div>
      </div>
    </div>
  </div> -->
</div>
