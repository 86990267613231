<div class="delivered-orders-list-data-wrapper-container">
  <h2 class="table-title">{{ 'ORDERLIST.TITLE.DELIVERED' | translate }}</h2>
  <div *ngIf="loaded">
    <app-delivered-orders-list-datas [deliveredOrders]="orders" [getDatas]="getOrders"></app-delivered-orders-list-datas>
    <div class="more" *ngIf="!getOrders">
      <div [ngClass]="loadedMore ? 'loading' : ''" (click)="getDatas()">
        <p class="uppercase">{{ 'GLOBAL.ACTION.VIEW' | translate }}</p>
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </div>
    </div>
    <div class="more" *ngIf="(orderService.currentDeliveredClientOrdersHasMore$ | async) && orders.length > 0 && getOrders">
      <div [ngClass]="loadedMore ? 'loading' : ''" (click)="getMoreDatas()">
        <p class="uppercase">{{ 'GLOBAL.ACTION.MORE' | translate }}</p>
        <svg viewBox="0 0 32 32">
          <use xlink:href="#icon-arrow-right"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="profile-main flex center-all loading" *ngIf="!loaded">
    <div class="loader"></div>
  </div>
</div>
