import { Component, OnInit } from '@angular/core';
import { DatatableService } from '../../../../../core/services/datatable.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Action } from '../../../../../core/models/action.model';
import { LoaderService } from '../../../../../core/services/loader.service';
import { TriggerService } from '../../../../../core/services/trigger.service';
import { JWTService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-point-history-data-wrapper',
  templateUrl: './point-history-data-wrapper.component.html',
  styleUrls: ['./point-history-data-wrapper.component.scss'],
})
export class PointHistoryDataWrapperComponent implements OnInit {
  formFilter: UntypedFormGroup;
  pageSize = 5;
  pageIndex = 0;
  hasMore: boolean;
  actions: Action[];
  loaded = false;
  firstCall = false;
  loadedMore = false;

  constructor(
    public datatableService: DatatableService,
    private jwtService: JWTService,
    private formBuilder: UntypedFormBuilder,
    private loader: LoaderService,
    private trigger: TriggerService
  ) {
    this.loader.loaded$.subscribe(res => {
      if (this.firstCall) {
        this.loadedMore = false;
      }
      if (!this.firstCall) {
        this.firstCall = true;
      }
      this.loaded = res;
    });
  }

  ngOnInit() {
    this.datatableService.actionDatas$.subscribe(data => {
      if (this.pageIndex == 0)
        this.actions = data.filter(x => {
          switch (x.clientActionTypeId) {
            case 1:
            case 21:
            case 22:
            case 20:
            case 11:
            case 10:
            case 12:
            case 13:
            case 23:
            case 80:
            case 81:
            case 90:
            case 91:
            case 200:
            case 220:
            case 221:
            case 222:
            case 201:
            case 303:
            case 304:
              return x;
          }
        });
      else
        this.actions.push(
          ...data.filter(x => {
            switch (x.clientActionTypeId) {
              case 1:
              case 21:
              case 22:
              case 20:
              case 11:
              case 10:
              case 12:
              case 13:
              case 23:
              case 80:
              case 81:
              case 90:
              case 91:
              case 200:
              case 220:
              case 221:
              case 222:
              case 201:
              case 303:
              case 304:
                return x;
            }
          })
        );
    });

    this.trigger.refreshHistoryActionsTable$.subscribe(res => {
      if (res) {
        this._resetDatatable();
      }
    });

    this.formFilter = this.formBuilder.group({
      showPurchases: [true],
      showVouchers: [true],
      showOthers: [true],
    });

    this.formFilter.controls.showPurchases.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.loadedMore = true;
      this._getValues();
    });

    this.formFilter.controls.showVouchers.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.loadedMore = true;
      this._getValues();
    });

    this.formFilter.controls.showOthers.valueChanges.subscribe(value => {
      this.pageIndex = 0;
      this.loadedMore = true;
      this._getValues();
    });

    this._getValues();
  }

  getMoreDatas() {
    this.pageIndex += 1;
    this.loadedMore = true;
    this._getValues();
  }

  private _resetDatatable() {
    this.pageIndex = 0;
    this._getValues();
  }

  private _getValues() {
    //this.loaded = false;
    this.datatableService.getActions(this.jwtService.getSubFromToken(), this.formFilter.getRawValue(), this.pageIndex, true);
  }
}
