import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ErrorService } from 'src/app/core/services/error.service';
import { Action } from '../../../../../core/models/action.model';
import { DateHelper } from '../../../../helpers/date.helper';

@Component({
  selector: 'app-point-history-datas',
  templateUrl: './point-history-datas.component.html',
  styleUrls: ['./point-history-datas.component.scss'],
})
export class PointHistoryDatasComponent implements OnInit {
  @Input() actions: Action[];
  @Output() triggerCall = new EventEmitter<string>();

  clientActionTypeIcon = {
    1: 'icon-basket', // purchase
    21: 'icon-bank-billet', // use cheque
    22: 'icon-bank-billet', // e-gift purchase
    23: 'icon-bank-billet', // Wallet use
    20: 'icon-bank-billet', // create-cheque
    10: 'icon-happy-emoji', // instagram
    11: 'icon-happy-emoji', // facebook
    12: 'icon-happy-emoji', // newsletter
    13: 'icon-happy-emoji', // Birthday
    200: 'icon-happy-emoji', // status change
    220: 'icon-happy-emoji', // booster level 1
    221: 'icon-happy-emoji', // sbooster level 2
    222: 'icon-happy-emoji', // booster level 3
    80: 'icon-happy-emoji', // Batch_AddBirthdayPoints
    81: 'icon-happy-emoji', // Batch_AddAdditionalLogs
    90: 'icon-sad-emoji', // Batch_AutomaticPointExpiration
    91: 'icon-sad-emoji', // Batch_AutomaticVoucherExpiration
    201: 'icon-sad-emoji', // Log Change Status DOWN
    303: 'icon-happy-emoji', // Goodies Status 3
    304: 'icon-happy-emoji', // Goodies Status 4
  };

  Math: any = Math;

  constructor(public error: ErrorService) {}

  ngOnInit() {}

  getDateForm(date: any): string {
    if (date.includes('/')) {
      const dateParts: any = date.split('/');
      const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return dateObject.toLocaleDateString('fr-BE');
    } else {
      return DateHelper.getDateLocalFormat(date);
    }
  }

  copyValue(el: any, value: string) {
    navigator.clipboard.writeText(value).then(r => {});
    el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.add('display');
    setTimeout(() => {
      el.target.closest('div').getElementsByClassName('icon-copy-check')[0]?.classList.remove('display');
    }, 3000);
  }
}
