import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderButtonService {
  protected _loaderButton$: BehaviorSubject<any> = new BehaviorSubject(false);
  public loaderButton$: Observable<any> = this._loaderButton$.asObservable();

  constructor() {}
}
