import { CartItemResponse, CartResponse, CartStatus, UserType, DeliveryType, PaymentType } from '@victoria-company/agora-client';
import { ProductVariantVM } from './productVariantVM.model';
import { ProductSearchModel } from '@victoria-company/agora-client/dist/models';

export class CartVM {
  cartId: string;

  status: CartStatus;
  value: number;

  userId: string;
  demoId: string;
  deliveryType: DeliveryType;
  paymentType: PaymentType;
  approvedByDelegate: boolean;
  dipSigned: boolean;
  dipSignedBy: UserType;

  //Financials
  total: number;
  totalToPay: number;
  deliveryFee: number;
  walletAmount: number;
  vouchersTotal: number;

  //Items
  cartItems: CartItemVM[] = [];

  hasItems = false;
  itemsCount = 0;

  public static updateVM(vm: CartVM, cart: CartResponse) {
    if (cart == undefined) return;
    if (vm == undefined) vm = CartVM.getVMFromData(cart);

    CartVM.set(vm, cart);
    return vm;
  }

  private static getVMFromData(cart: CartResponse) {
    if (cart == undefined) return;

    const vm = new CartVM();
    CartVM.set(vm, cart);
    return vm;
  }

  private static set(vm: CartVM, cart: CartResponse) {
    let itemsCount = 0;

    vm.cartItems = [];
    vm.cartId = cart.id;
    vm.status = cart.status;
    vm.userId = cart.userId;
    vm.demoId = cart.demoId;
    vm.deliveryType = cart.delivery.type;
    vm.paymentType = null;
    vm.approvedByDelegate = cart.approval?.approvedByDelegate;
    vm.dipSigned = cart.preContractualInformationDocument?.signed;
    vm.dipSignedBy = cart.preContractualInformationDocument?.markedAsSignedBy;

    vm.total = cart.total;
    vm.totalToPay = cart.totalToPay;
    vm.deliveryFee = cart.deliveryFee;
    vm.walletAmount = cart.walletAmount;

    cart.cartItems.forEach(x => {
      vm.cartItems.push(CartItemVM.getVMFromData(x, vm.cartId));
      itemsCount += x.quantity;
    });

    vm.hasItems = vm.cartItems.length > 0;
    vm.itemsCount = itemsCount;
  }
}

export class CartItemVM {
  cartId: string;
  articleGroupId: string;

  productVariantId: string;
  quantity: number;
  size: string;
  unitPrice: number;
  total: number;

  stock: number;
  sku: string;
  name: string;
  nameShort: string;
  productImage: string;
  hoverImage: string;
  tagLabel: string;
  tagMarketing: string;

  variants: ProductVariantVM[] = [];

  hasVariant = () => this.variants.length > 1;

  public updateCartItemFromProduct(product: ProductSearchModel) {
    const selectedVariant = product.variants.find(v => v.id == this.productVariantId);
    this.variants = product.variants.map(x => ProductVariantVM.create(x));

    this.articleGroupId = product.id;
    this.stock = selectedVariant.stock;
    this.sku = selectedVariant.sku;
    this.size = selectedVariant.size;

    this.name = product.name;
    this.nameShort = product.name;
    this.productImage = product.imageThumbnail;
    this.hoverImage = product.imageHover;
    this.tagLabel = product.tagLabel;
    this.tagMarketing = product.tagMarketing;
  }

  public static getVMFromData(item: CartItemResponse, cartId: string) {
    const vm = new CartItemVM();
    vm.cartId = cartId;
    vm.productVariantId = item.productVariantId;
    vm.quantity = item.quantity;
    vm.unitPrice = item.unitPrice;
    vm.total = item.total;

    return vm;
  }
}
