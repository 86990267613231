<div class="progress-demo-container" *ngIf="isLoaded()">
  <div class="container">
    <div class="demo-image-container background-image">
      <img loading="lazy" src="/assets/images/demo.png" />
    </div>
    <div class="demo-content-container text-container">
      <div class="demo-participation-container">
        <img loading="lazy" class="logo" src="/assets/images/logo.svg" alt="Logo Victoria" />
        <div class="demo-participation">
          <div class="has-demo" *ngIf="requestedDemo()">
            <div class="flex column ai-center">
              <h2 class="bold">{{ 'DEMO.ONBOARDING.DATE' | translate: { X: requestedDemo().demo?.date | date } }}</h2>
              <p class="p-s">{{ 'DEMO.LIST.ITEM.HOSTESS' | translate: { hostessName: requestedDemo().demo?.hostessDisplayName } }}</p>
            </div>
            <div class="content-box">
              <p class="p-s">{{ 'DEMO.ONBOARDING.DELEGATE' | translate: { X: requestedDemo().demo?.delegateDisplayName } }}</p>
            </div>
            <div *ngIf="requestedDemo().demo?.status == DemoStatusObject.PreSale">
              <ng-container *ngTemplateOutlet="PreSale"></ng-container>
            </div>
            <div *ngIf="requestedDemo().demo?.status == DemoStatusObject.Opened">
              <ng-container *ngTemplateOutlet="Opened"></ng-container>
            </div>
            <div *ngIf="requestedDemo().demo?.status == DemoStatusObject.PostSale">
              <ng-container *ngTemplateOutlet="PostSale"></ng-container>
            </div>
            <div *ngIf="requestedDemo().demo?.status == DemoStatusObject.Closed">
              <ng-container *ngTemplateOutlet="Closed"></ng-container>
            </div>
          </div>
          <div data-testId="noDemoToJoin" class="no-demo" *ngIf="!requestedDemo()">
            <ng-container *ngTemplateOutlet="Error"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="progress-demo-container loading flex center-all" *ngIf="!isLoaded()">
  <div class="loader"></div>
</div>

<ng-template #PreSale>
  <div *ngIf="!isUserFromDemo()">
    <p>{{ 'DEMO.ONBOARDING.WELCOME' | translate: { X: requestedDemo().demo?.hostessDisplayName, Y: requestedDemo().demo?.date | date } }}</p>
    <p *ngIf="!isUserAuthenticated()">{{ 'DEMO.ONBOARDING.CONNECT' | translate }}</p>
    <label class="flex row checkbox path checkbox-optin" *ngIf="isUserAuthenticated()">
      <input data-testId="privacyConsent" type="checkbox" [checked]="isOptin()" (change)="changeOptIn()" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      <p class="p-s">{{ 'DEMO.ONBOARDING.OPTIN.LABEL' | translate }}</p>
    </label>
    <div class="cta-container">
      <button *ngIf="isUserAuthenticated()" data-testId="joinFuturDemo" (click)="joinDemo()" [disabled]="!isOptin()">
        <label>{{ 'DEMO.ONBOARDING.INSCRIPTION' | translate }}</label>
      </button>
      <button *ngIf="!isUserAuthenticated()" data-testId="joinFuturDemo" (click)="authenticate()">
        <label>{{ 'DEMO.LANDING.CONNECTION' | translate }} </label>
      </button>
    </div>
    <div class="demo-no-present">
      <p>{{ 'DEMO.ONBOARDING.NO_PRESENT' | translate }}</p>
      <div class="cta-container">
        <button data-testId="discoverCollection" class="secondary" (click)="navigateUrl(resourcesStore.getEshopCollectionLink())">
          <label>{{ 'DEMO.ONBOARDING.DISCOVER' | translate }}</label>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isUserFromDemo()">
    <p>{{ 'DEMO.ONBOARDING.THANKS_INSCRIPTION' | translate }}</p>
    <p>{{ 'DEMO.ONBOARDING.DELEGATE_KNOWS' | translate }}</p>
    <p class="lightbold">{{ 'DEMO.ONBOARDING.REMAINING_DAYS' | translate: { X: getRemainingDays(requestedDemo().demo?.date) } }}</p>
    <p>{{ 'DEMO.ONBOARDING.COLLECTION' | translate }}</p>
    <div class="cta-container">
      <button data-testId="demoViewCollection" (click)="navigateUrl(resourcesStore.getEshopCollectionLink())">
        <label>{{ 'DEMO.ONBOARDING.WATCH_COLLECTION' | translate }}</label>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Opened>
  <div>
    <p>{{ 'DEMO.ONBOARDING.WELCOME2' | translate: { X: requestedDemo().demo?.hostessDisplayName } }}</p>
    <p *ngIf="!isUserAuthenticated()">{{ 'DEMO.ONBOARDING.JOIN' | translate }}</p>
    <label class="flex row checkbox path checkbox-optin" *ngIf="isUserAuthenticated()">
      <input data-testId="privacyConsent" type="checkbox" [checked]="isOptin()" (change)="changeOptIn()" />
      <svg viewBox="0 0 28 28">
        <use xlink:href="#custom-checkbox"></use>
      </svg>
      <p class="p-s">{{ 'DEMO.ONBOARDING.OPTIN.LABEL' | translate }}</p>
    </label>
    <div class="cta-container">
      <button *ngIf="isUserAuthenticated()" data-testId="joinFuturDemo" (click)="joinDemo()" [disabled]="!isOptin()">
        <label>{{ 'DEMO.ONBOARDING.PARTICIPATE2' | translate }}</label>
      </button>
      <button *ngIf="!isUserAuthenticated()" data-testId="joinFuturDemo" (click)="authenticate()">
        <label>{{ 'DEMO.LANDING.CONNECTION' | translate }}</label>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #PostSale>
  <p>{{ 'DEMO.ONBOARDING.WELCOME3' | translate: { X: requestedDemo().demo?.hostessDisplayName, Y: requestedDemo().demo?.date | date } }}</p>
  <div>
    <div class="cta-container">
      <button (click)="goToEshop()">
        <label>{{ 'DEMO.ONBOARDING.ORDER' | translate }}</label>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Closed>
  <p>{{ 'DEMO.ONBOARDING.CLOSED' | translate }}</p>
  <div>
    <div class="cta-container">
      <button (click)="navigateUrl(resourcesStore.getEshopCollectionLink())">
        <label>{{ 'DEMO.ONBOARDING.WISHLIST' | translate }}</label>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #Error>
  <div class="oops-block">
    <svg viewBox="0 0 32 32" class="peach">
      <use xlink:href="#icon-oops"></use>
    </svg>
    <p>Oops!</p>
  </div>
  <p class="wrong_link">{{ 'DEMO.ONBOARDING.WRONG_LINK' | translate }}</p>
  <p>{{ 'DEMO.ONBOARDING.WRONG_LINK.DETAILS' | translate }}</p>
  <hr />
  <p>{{ 'DEMO.ONBOARDING.WEBSITE' | translate }}</p>
  <div class="link">
    <app-wavedlink data-testId="noDemoRedirectionLink" [linkText]="'DEMO.ONBOARDING.VICTORIA' | translate" [externalUrl]="getSiteUrl()"></app-wavedlink>
  </div>
</ng-template>
