<div class="pink-area fullwidth">
  <form  *ngIf="editForm" [formGroup]="editForm" class="form-container">
    <div class="form-block">
      <div class="form-control">
        <label>Titre</label>
        <input type="text" formControlName="title" />
        <p class="form-error" *ngIf="(editForm.get('title')?.dirty || editForm.get('title')?.touched) && editForm.get('title')?.errors?.required">Veuillez renseigner le titre</p>
      </div>
    </div>
    <div class="form-block flex gap-32 j-space-between m-column m-gap-initial">
      <div class="form-control flex-1">
        <label>Type</label>
        <select formControlName="type">
          <option *ngIf="editForm.get('category').value==DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Customer">Action Cliente</option>
          <option *ngIf="editForm.get('category').value==DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Hostess">Action Hôtesse</option>
          <option *ngIf="editForm.get('category').value==DocumentCategoryObject.CommercialAction" [value]="DocumentTypeObject.Delegate">Action Conseillère</option>
          <option *ngIf="editForm.get('category').value==DocumentCategoryObject.HostessGuide" [value]="DocumentTypeObject.Hostess">Guide Hôtesse</option>
        </select>
        <p class="form-error" *ngIf="(editForm.get('type')?.dirty || editForm.get('type')?.touched) && editForm.get('type')?.errors?.required">Sélectionnez le type de document</p>
      </div>
      <div class="form-control flex-1">
        <label>Language</label>
        <select formControlName="locale">
          <option *ngIf="contextStore.contextId()==1" value="fr_fr">Français</option>
          <option *ngIf="contextStore.contextId()==2" value="fr_be">Français</option>
          <option *ngIf="contextStore.contextId()==2" value="nl_be">Nederlands</option>
          <option *ngIf="contextStore.contextId()==3" value="de_de">Deutsch</option>

        </select>
        <p class="form-error" *ngIf="(editForm.get('locale')?.dirty || editForm.get('locale')?.touched) && editForm.get('locale')?.errors?.required">Sélectionnez une langue</p>
      </div>
    </div>
    <div class="form-block-flex">
      <div class="form-control" *ngIf="editForm.get('category').value==DocumentCategoryObject.CommercialAction">
        <label>Date de début de l'action</label>
        <input type="date" formControlName="appliesFrom" />
        <p class="form-error" *ngIf="(editForm.get('appliesFrom')?.dirty || editForm.get('appliesFrom')?.touched) && editForm.get('appliesFrom')?.errors?.required">Sélectionnez une date de début</p>
      </div>
      <div class="form-control" *ngIf="editForm.get('category').value==DocumentCategoryObject.CommercialAction">
        <label>Date de fin de l'action</label>
        <input type="date" formControlName="appliesUntil" />
        <p class="form-error" *ngIf="(editForm.get('appliesUntil')?.dirty || editForm.get('appliesUntil')?.touched) && editForm.get('appliesUntil')?.errors?.required">Sélectionnez une date de fin</p>
      </div>
      <div class="form-control">
        <label>Date de début de visibilité</label>
        <input type="date" formControlName="visibleFrom" />
        <p class="form-error" *ngIf="(editForm.get('visibleFrom')?.dirty || editForm.get('visibleFrom')?.touched) && editForm.get('visibleFrom')?.errors?.required">Sélectionnez une date de début</p>
      </div>
      <div class="form-control">
        <label>Date de fin de visibilité</label>
        <input type="date" formControlName="visibleUntil" />
        <p class="form-error" *ngIf="(editForm.get('visibleUntil')?.dirty || editForm.get('visibleUntil')?.touched) && editForm.get('visibleUntil')?.errors?.required">Sélectionnez une date de fin</p>
      </div>
      <div class="form-control">
        <label>Description</label>
        <textarea formControlName="description"></textarea>
        <p class="form-error" *ngIf="(editForm.get('description')?.dirty || editForm.get('description')?.touched) && editForm.get('description')?.errors?.required">Veuillez introduire une brêve description</p>
      </div>
      <div class="form-control">
        <label>Image</label>
        <app-file-dropzone (requestUploadFile)="uploadFile($event, FileType.Image)" (requestRemoveAttachment)="removeAttachment(FileType.Image)" [dropzoneText]="'file upload area'" [attachmentSrc]="editForm.get('imageUrl')?.value" [style]="'min-height:15rem;'" />
        <input type="hidden" formControlName="imageUrl">
      </div>
      <div class="form-control">
        <label>Pièce jointe</label>
        <app-file-dropzone  (requestUploadFile)="uploadFile($event, FileType.Attachment)" (requestRemoveAttachment)="removeAttachment(FileType.Attachment)"  [attachmentName]="editForm.get('attachments')?.value.length ? 'Pièce jointe' : ''" [dropzoneText]="'attachment upload area'" />
      </div>
      <div class="form-control">
        <label>Lien</label>
        <input type="text" formControlName="link" />
      </div>
      <div class="form-control">
        <label>Audiences</label>
        <div class="flex j-space-between gap-15" style="margin-top:1.5rem; padding:0.5rem" formGroupName="audiences">
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_customer">
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            Customer
          </label>
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_hostess">
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            Hostess
          </label>
          <label class="flex row checkbox path">
            <input type="checkbox" formControlName="audiences_delegate">
            <svg viewBox="0 0 28 28">
              <use xlink:href="#custom-checkbox"></use>
            </svg>
            Delegate
          </label>
        </div>
        <p class="form-error" *ngIf="(editForm.get('audiences')?.dirty || editForm.get('audiences')?.touched) && editForm.get('audiences')?.errors?.audiencesRequired">Veuillez sélectionner une audience</p>
      </div>
    </div>
    <div class="cta-container width-fit">
      <button (click)="validateForm()"><label>Enregistrer</label></button>
    </div>
  </form>
</div>
