import { environment } from '../../../environments/environment';
import { CatalogService } from '../services/V2/catalog.service';
import { DemoHostessGifts } from '../../stores/demo.store';

export const getIntranetUrlByContext = (contextId:number) => {
  const url = environment.intranetUrlByContext[contextId];
  const production = environment.production;

  return production ? url.replace("{env}","intranet") : url.replace("{env}","acceptance")
}

export const getProductsFromGiftsResponse = async (gifts:DemoHostessGifts, locale:string, contextId:number, catalogService:CatalogService) => {
  const productIds = gifts.giftsForCategories?.flatMap(gfc => gfc.choices)?.map(c => c.productId) ?? [];

  //Load products if has null
  if(productIds.length &&  gifts.giftsForCategories?.flatMap(gfc => gfc.choices.some(c => c.product==null))){
    console.log("Load products for gifts")
    const products = await catalogService.getProductsPromise(contextId, locale, productIds);
    gifts.giftsForCategories.forEach(gfc => gfc.choices.forEach(c => {
      c.product = products.products.find(p => p.id==c.productId)
    }))
  }
}
