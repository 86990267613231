<div class="order-container" *ngIf="resourcesStore.isLoaded() && cart && (payment || cart.status == 'Finalized' || cart.status == 'Confirmed')">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle"> </app-page-title>
  <div class="frame-container flex j-center ai-start">
    <app-order-payment-status
      *ngIf="cart.status == 'Finalized' || cart.status == 'Confirmed' || (payment && (payment?.status == 'Paid' || payment?.type == PaymentTypeObject.HandledByDelegate))"
      [paymentType]="cart.totalToPay == 0 ? undefined : payment?.type"
      [cart]="cart"
      [email]="userStore.profile()?.email"></app-order-payment-status>
    <app-order-payment-pending
      *ngIf="(cart.status == 'Active' || cart.status == 'PendingPayment') && payment.status != 'Paid' && payment.type != PaymentTypeObject.HandledByDelegate"></app-order-payment-pending>
  </div>
</div>
