import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ResourcesStore } from '../../../../../stores/resources.store';
import { CheckoutStore } from '../../../../../stores/chechout.store';

@Component({
  selector: 'app-encoding-demo-code-modal',
  templateUrl: './encoding-demo-code-modal.component.html',
  styleUrls: ['./encoding-demo-code-modal.component.scss'],
})
export class EncodingDemoCodeModalComponent {
  resourcesStore = inject(ResourcesStore);
  checkoutStore = inject(CheckoutStore);

  @Input() isOpened: boolean;
  @Input() demoCode: string;
  @Input() error: string;
  @Output() requestCloseEnterDemoCodeModal = new EventEmitter();

  async addCodeToDemo() {
    if (!this.demoCode) return;
    await this.checkoutStore.addDemoCodeToCart(this.demoCode);
  }

  close() {
    this.requestCloseEnterDemoCodeModal.emit();
  }
}
