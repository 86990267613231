import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { getCookieDomainByLang, getRootUrl } from 'src/app/core/utils/filter.utils';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from 'src/app/core/services/storage.service';
import { DemoService } from 'src/app/features/agora-pages/demo/services/demo.service';

@Component({
  selector: 'app-esolanding',
  templateUrl: './eso-landing.component.html',
  styleUrls: ['./eso-landing.component.scss'],
})
export class EsoLandingComponent implements OnInit {
  private vLocale: string;
  private demoIdCookie: string;
  private delegateCookie: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookie: CookieService,
    private storage: StorageService,
    private demoService: DemoService
  ) {}

  ngOnInit() {
    let delegateId = this.activatedRoute.snapshot.params?.delegateId;
    this.delegateCookie = this.storage.delegateId;
    this.demoIdCookie = this.storage.demoId;

    this.vLocale = this.cookie.get('v_locale');

    if (!delegateId) this.redirectToSite();
    else {
      //transform Delegate Code into Delegate Id
      delegateId = delegateId?.split('-')[0];

      if (!this.delegateCookie || this.delegateCookie != delegateId) {
        this.checkDelegateId(delegateId);
      } else if (this.delegateCookie == delegateId) {
        this.checkDelegateId(delegateId, true);
      }
    }
  }

  private checkDelegateId(delegateId, preserveDemoId = false) {
    this.demoService.getDelegateDatas(delegateId).subscribe(
      result => {
        if (result && result.data && result.data.isActive) {
          this.storage.delegateId = delegateId;

          if (this.demoIdCookie && !preserveDemoId) this.storage.removeDemoId();

          if (result.data.languages[0]) {
            this.cookie.set('v_locale', result.data.languages[0], 365, '/', getCookieDomainByLang(result.data.languages[0]));
            this.vLocale = result.data.languages[0]?.replace('-', '_');
          }
        } else {
          //If inactive delegate and delegateIdCookie exists, drop cookie
          if (this.delegateCookie && this.delegateCookie == delegateId) {
            this.storage.removeDelegateId();
            //drop also DemoId cookie if exists
            if (this.demoIdCookie) this.storage.removeDemoId();
          }
        }

        this.redirectToSite();
      },
      err => this.redirectToSite()
    );
  }

  private redirectToSite() {
    window.location.href = getRootUrl(this.vLocale);
  }
}
