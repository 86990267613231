import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Referrals } from 'src/app/core/models/referrals.model';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { NavigatorShareService } from 'src/app/core/services/navigator-share.service';

@Component({
  selector: 'app-booster',
  templateUrl: './booster.component.html',
  styleUrls: ['./booster.component.scss'],
})
export class BoosterComponent implements OnInit, OnDestroy {
  hasBoosters = true;
  locale: string;
  referralLink: string;
  referralsData: Referrals;

  @ViewChild('conditions') cdtElement: ElementRef;

  //NG LifeCycle
  subscriptions: Subscription[] = [];

  constructor(
    private getDatasService: GetDatasService,
    private jwt: JWTService,
    private navigatorShare: NavigatorShareService,
    private translate: TranslateService,
    private router: Router,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.jwt.hasDelegateRole$.subscribe(res => {
        this.locale = this.cookie.get('v_locale')?.replace('_', '-');

        if (res === true) {
          this.router.navigate([`/${this.locale}/club/home`]);
        }
      })
    );

    this.subscriptions.push(
      this.getDatasService.clientData$.subscribe(res => {
        if (res) {
          this.referralLink = res.referralLink;
          this.getDatasService.getReferralsInfos(this.jwt.getSubFromToken(), res.id);
        }
      })
    );

    this.subscriptions.push(
      this.getDatasService.clientReferrals$.subscribe(data => {
        if (data) {
          this.referralsData = data;
        }
      })
    );
  }

  share() {
    if (!this.navigatorShare.canShare() && this.referralLink) {
      alert(`This service/api is not supported in your Browser`);
      return;
    }

    this.navigatorShare
      .share({
        title: 'Referral Link',
        text: this.translate.instant('SHARE.REFERRALLINK.TEXT'),
        url: this.referralLink,
      })
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
  }

  copy() {
    navigator.clipboard.writeText(this.referralLink).then(r => {});

    document.getElementById('icon-copy-booster-check')?.classList.toggle('display');
    document.getElementById('icon-copy-booster')?.classList.toggle('display');
    setTimeout(() => {
      document.getElementById('icon-copy-booster-check')?.classList.toggle('display');
      document.getElementById('icon-copy-booster')?.classList.toggle('display');
    }, 1000);
  }

  displayConditions() {
    if (this.cdtElement) {
      this.cdtElement?.nativeElement?.classList?.toggle('show');
    }
  }
  @HostListener('window:beforeunload')
  @HostListener('unload')
  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
