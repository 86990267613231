<div class="discount-box">
  <div class="voucher-encoding-container">
    <div class="header" *ngIf="!isValidationTunnel">{{ 'ORDER_TUNNEL.REDUCTIONS.VOUCHER' | translate }}</div>
    <div *ngIf="isValidationTunnel">
      <h1 class="canela">{{ 'DEMO.TUNNEL.REDUCTIONS.TITLE' | translate }}</h1>
      <div class="header order-validation">{{ 'DEMO.TUNNEL.CLIENT.VOUCHERS' | translate }}</div>
    </div>
    <div class="form-control flex row j-space-between ai-center input-box m-gap-20" [class.error]="stateStatus.error">
      <input
        data-testId="voucher-encoding"
        [placeholder]="'ORDER_TUNNEL.REDUCTIONS.VOUCHER.PLACEHOLDER' | translate"
        class="voucher-input"
        [attr.disabled]="cart?.totalToPay - cart.deliveryFee > 0 ? null : ''"
        [(ngModel)]="voucherCode" />
      <button
        data-testId="voucher-encoding-action"
        class="btn btn-primary btn-animated {{ cart?.totalToPay - cart.deliveryFee > 0 && voucherCode ? '' : 'disabled' }} encode-voucher-done"
        [ngClass]="voucherCode ? 'secondary' : ''"
        (click)="validateVoucherCode()"
        [disabled]="stateStatus.isBusy">
        <span *ngIf="!stateStatus.added">{{ 'GLOBAL.ACTION.USE' | translate }}</span>
        <span *ngIf="stateStatus.added">{{ 'GLOBAL.ACTION.USED' | translate }}</span>
        <svg viewBox="0 0 32 32" class="icon encode-voucher-check">
          <use xlink:href="#icon-checkmark"></use>
        </svg>
        <div *ngIf="stateStatus.isBusy" class="loader loader-mini"></div>
      </button>
    </div>
    <div data-testId="voucher-encoding-errors" class="error-container" [class.error]="stateStatus.error">
      <label *ngIf="stateStatus.error">{{ stateStatus.error }}</label>
    </div>
  </div>
</div>
