/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticService {
  loaded = false;
  GA_TRAKINK_ID;

  constructor() {}

  //Call Once in AppComponent
  public createGAElements(contextId: number, render: Renderer2, document: Document) {
    if (this.loaded || !environment.production) return;

    this.setGATrackingID(contextId);

    let element = render.createElement('script');
    element.src = this.loadGAScripts(contextId);
    element.async = true;
    render.appendChild(document.body, element);

    element = render.createElement('script');
    element.text = this.initPagePreviewHit();
    render.appendChild(document.body, element);
  }

  public GARemoveFromCart(product: any, sku: string, quantity: number) {
    if (window.dataLayer && environment.production) {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: product.productGroup?.nameOnly, //Petit nom
                id: product.productGroup?.code, //groupcode
                price: product.unitPrice,
                brand: 'Victoria',
                category: product.productGroup?.category?.slug, //le nom de la categorie
                variant: sku, //sku
                quantity,
              },
            ],
          },
        },
      });
    }
  }

  public GAAddToCart(product: any, sku: string) {
    (window as any).rudderanalytics.track('addToCart', {
      products: [
        {
          name: product.productGroup?.nameOnly,
          id: product.productGroup?.code,
          price: product.unitPrice,
          category: product.productGroup?.category?.slug,
          variant: sku,
          quantity: 1,
        },
      ],
    });

    if (window.dataLayer && environment.production) {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: product.productGroup?.nameOnly,
                id: product.productGroup?.code,
                price: product.unitPrice,
                brand: 'Victoria',
                category: product.productGroup?.category?.slug,
                variant: sku,
                quantity: 1,
              },
            ],
          },
        },
      });
    }
  }

  public GACheckout(step: number, option: string, order: any) {
    let products = [];
    const cioProducts = [];
    order.orderItems.forEach(product => {
      products.push({
        name: product.productGroup?.nameOnly,
        id: product.productGroup?.code,
        price: product.unitPrice,
        brand: 'Victoria',
        category: product.productGroup?.category?.slug,
        variant: product.sku,
        quantity: product.quantity,
      });
      cioProducts.push({
        name: product.productGroup?.nameOnly,
        product_id: product.productGroup?.code,
        price: product.unitPrice,
        brand: 'Victoria',
        category: product.productGroup?.category?.slug,
        sku: product.sku,
        quantity: product.quantity,
      });
    });

    (window as any).rudderanalytics.track('checkout', {
      order_id: order.id,
      revenue: order.totalToPay,
      shipping: order.deliveryFee,
      discount: 0,
      currency: 'EUR',
      products: cioProducts,
      value: order.totalToPay,
    });

    if (window.dataLayer && environment.production) {
      products = [];
      order.orderItems.forEach(product => {
        products.push({
          name: product.productGroup?.nameOnly,
          id: product.productGroup?.code,
          price: product.unitPrice,
          brand: 'Victoria',
          category: product.productGroup?.category?.slug,
          variant: product.sku,
          quantity: product.quantity,
        });
      });

      const checkoutDatas = {
        actionField: { step, option },
        products,
      };

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: checkoutDatas,
        },
      });
    }
  }

  public GAOrderConfirmed(order: any) {
    if (window.dataLayer && environment.production) {
      const products = [];
      const cioProducts = [];
      order.orderItems.forEach(product => {
        products.push({
          name: product.productGroup?.nameOnly,
          id: product.productGroup?.code,
          price: product.unitPrice,
          brand: 'Victoria',
          category: product.productGroup?.category?.slug,
          variant: product.sku,
          quantity: product.quantity,
        });
        cioProducts.push({
          name: product.productGroup?.nameOnly,
          product_id: product.productGroup?.code,
          price: product.unitPrice,
          brand: 'Victoria',
          category: product.productGroup?.category?.slug,
          sku: product.sku,
          quantity: product.quantity,
        });
      });

      const purchaseDatas = {
        actionField: {
          id: order.id,
          affiliation: 'Victoria online shop',
          revenue: order.totalToPay,
          shipping: order.deliveryFee,
          items: order.orderItemsCount,
        },
        products,
      };

      (window as any).rudderanalytics.track('purchase', {
        order_id: order.id,
        revenue: order.totalToPay,
        shipping: order.deliveryFee,
        discount: 0,
        currency: 'EUR',
        products: cioProducts,
        value: order.totalToPay,
      });

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: purchaseDatas,
        },
      });
    }
  }

  private setGATrackingID(contextId) {
    switch (contextId) {
      case 1:
        this.GA_TRAKINK_ID = 'GTM-NVTMTL4';
        break;
      case 2:
        this.GA_TRAKINK_ID = 'GTM-5LMF6SM';
        break;
      case 3:
        this.GA_TRAKINK_ID = 'GTM-53J29LR';
        break;
      default:
        this.GA_TRAKINK_ID = '0';
        break;
    }
  }

  private loadGAScripts(contextId: number) {
    return `https://www.googletagmanager.com/gtm.js?id=${this.GA_TRAKINK_ID}`;
  }

  private initPagePreviewHit() {
    this.loaded = true;
    return `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${this.GA_TRAKINK_ID}');
      `;
  }
}
