import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ObjectLiteral } from '../../shared/types/object.type';
import { JWTService } from './jwt.service';

export abstract class CRUDService<T, U = {}> {
  protected API_URL: string;
  protected headers = new Headers();
  protected options: any;

  constructor(
    protected httpClient: HttpClient,
    protected jwtService: JWTService
  ) {
    //     this.headers.append('x-api-key', environment.api_key);
    //    this.headers.append('Authorization', `Bearer ${authService.getAccessToken()}`);

    this.options = { headers: history };
  }

  protected getAPIUrl(subIds?: U) {
    if (!subIds) {
      return this.API_URL;
    }
    let url = this.API_URL;
    for (const property in subIds) {
      if (subIds.hasOwnProperty(property)) {
        url = url.replace(`:${property}`, subIds[property] as any);
      }
    }
    return url;
  }

  getManyByFilter(filter?: ObjectLiteral, subIds?: U) {
    return this.httpClient.get<T[]>(this.getAPIUrl(subIds), { params: filter });
  }

  getMany(subIds?: U) {
    return this.httpClient.get<T[]>(this.getAPIUrl(subIds));
  }

  getLight(filter?: ObjectLiteral, subIds?: U) {
    return this.httpClient.get<T[]>(this.getAPIUrl(subIds) + '/light', { params: filter });
  }

  getOne(id: number | string, subIds?: any): Observable<T> {
    return this.httpClient.get<T>(this.getAPIUrl(subIds) + `/${id}`, { params: subIds });
  }

  getOneWithAutentification(id: number | string, subIds?: any): Observable<T> {
    return this.httpClient.get<T>(this.getAPIUrl(subIds) + `/${id}`, { withCredentials: true, headers: { Authorization: `Bearer ${this.jwtService.getToken()}` }, params: subIds });
  }

  post(data: any, subIds?: U): Observable<T> {
    return this.httpClient.post<T>(this.getAPIUrl(subIds), data);
  }

  postFormData(data: any, subIds?: U): Observable<T> {
    return this.post(this._createFormData(data), subIds);
  }

  delete(id: number | string, subIds?: U): Observable<T> {
    return this.httpClient.delete<T>(this.getAPIUrl(subIds) + `/${id}`);
  }

  patch(id: number | string, data: any, subIds?: U): Observable<T> {
    return this.httpClient.patch<T>(this.getAPIUrl(subIds) + `/${id}`, data);
  }

  patchFormData(id: number | string, data: any, subIds?: U): Observable<T> {
    return this.patch(id, this._createFormData(data), subIds);
  }

  protected _createFormData<R>(entity: R) {
    const body = new FormData();

    this._appendProperties(body, entity, null);

    return body;
  }

  private _appendProperties(body: FormData, entity: any, baseProp: string | null) {
    for (const property in entity) {
      if (entity.hasOwnProperty(property)) {
        const value = entity[property];
        if (value instanceof File) {
          body.append(this._generatePropName(baseProp, property, null), value, value.name);
          // } else if (value instanceof Date) {
          //   body.append(this._generatePropName(baseProp, property, null), toJSONWithoutMs(value));
        } else if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            if (typeof value[i] === 'object') {
              this._appendProperties(body, value[i], this._generatePropName(baseProp, property, i));
            } else {
              body.append(this._generatePropName(baseProp, property, i), `${value[i]}`);
            }
          }
        } else if (value !== null && typeof value === 'object') {
          this._appendProperties(body, value, this._generatePropName(baseProp, property, null));
        } else {
          if (value !== null && value !== undefined) {
            body.append(this._generatePropName(baseProp, property, null), `${entity[property]}`);
          }
        }
      }
    }
  }

  private _generatePropName(baseProp: string | null, property: string, i: number | null): string {
    let name: string;

    if (baseProp === null) {
      name = property;
    } else {
      name = `${baseProp}[${property}]`;
    }

    if (i !== null && i !== undefined) {
      name += `[${i}]`;
    }

    return name;
  }
}
