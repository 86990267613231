<div class="wallet-datas-container">
  <p *ngIf="!vouchers.length && !(error.getVouchersError$ | async)" class="no-data-title">{{ 'DATATABLE.NODATA' | translate }}</p>
  <div class="error-box" *ngIf="error.getVouchersError$ | async">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{ 'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="d-only">
    <table class="data-table bottom-border no-line-height div-full-height" *ngIf="vouchers && vouchers.length">
      <tr class="tr-title">
        <th>{{ 'VOUCHER.TABLE.TITLE.TYPE' | translate }}</th>
        <th>{{ 'VOUCHER.TABLE.TITLE.STATUS' | translate }}</th>
        <th>{{ 'GLOBAL.VOUCHER.CODE' | translate }}</th>
        <th>{{ 'GLOBAL.VOUCHER.VALUE' | translate }}</th>
        <th>{{ 'GLOBAL.VOUCHER.EXPIREAT' | translate }}</th>
        <th>{{ 'GLOBAL.VOUCHER.USEDAT' | translate }}</th>
        <th>{{ 'GLOBAL.ACTIONS' | translate }}</th>
      </tr>
      <tr *ngFor="let voucher of vouchers; index as i">
        <td>{{ 'GLOBAL.VOUCHER.TYPE_' + voucher.typeId.toString().toUpperCase() | translate }}</td>
        <td>
          <div class="flex ai-center">
            <svg viewBox="0 0 14 14" class="icon peach status-icon">
              <use [attr.xlink:href]="voucher.usedDate ? '#red-point' : '#green-point'"></use>
            </svg>
            &nbsp; {{ (voucher.usedDate ? 'GLOBAL.STATUS.USED' : 'GLOBAL.STATUS.NOTUSED') | translate }}
          </div>
        </td>
        <td>
          <div class="flex row ai-center j-space-between voucher-row w90">
            <p class="flex ai-center code">{{ voucher.code }}</p>
            <div class="icon-container flex j-center">
              <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                <use xlink:href="#icon-checkmark"></use>
              </svg>
              <button class="btn-copy flex ai-end" (click)="copyValue($event, voucher.code)">
                <svg viewBox="0 0 32 32" class="icon peach">
                  <use xlink:href="#icon-copy"></use>
                </svg>
              </button>
            </div>
          </div>
        </td>
        <td>
          <p>{{ voucher.amount | agoraCurrency }}</p>
        </td>
        <td>
          <p>{{ voucher.expirationDate | date: 'dd/MM/yyyy' }}</p>
        </td>
        <td>
          <p>{{ voucher.usedDate | date: 'dd/MM/yyyy' }}</p>
        </td>
        <td class="actions-list">
          <div class="actions-dropdown" *ngIf="!voucher.usedDate">
            <button class="dropbtn {{ !!voucher.usedDate ? 'disabled' : '' }}">
              <div class="button-label flex ai-center">{{ 'GLOBAL.ACTIONS' | translate }}</div>
              <div class="dropbtn-icon flex ai-center">
                <svg viewBox="0 0 32 32">
                  <use xlink:href="#icon-arrow-right"></use>
                </svg>
              </div>
            </button>
            <div id="list-{{ i }}" class="dropdown-content {{ !voucher.usedDate ? 'he1' : '' }}">
              <a *ngIf="!voucher.usedDate" (click)="download(voucher.code, !!voucher.usedDate)">{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</a>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="m-only">
    <app-carousel *ngIf="vouchers" (endScrollEvent)="triggerCall.emit()">
      <div class="card" *ngFor="let voucher of vouchers">
        <div class="header">
          <div class="flex row j-space-between ai-center">
            <div class="voucher-type">
              <p>{{ 'GLOBAL.VOUCHER.TYPE_' + voucher.typeId.toString().toUpperCase() | translate }}</p>
            </div>
            <div class="flex row ai-center">
              <svg viewBox="0 0 16 16" class="icon peach height100">
                <use [attr.xlink:href]="'#' + (voucher.usedDate ? 'red-point' : 'green-point')"></use>
              </svg>
              &nbsp; {{ voucher.usedDate ? ('GLOBAL.STATUS.USED' | translate) : ('GLOBAL.STATUS.NOTUSED' | translate) }}
            </div>
          </div>
        </div>
        <div class="body">
          <div class="container">
            <p class="title-m" innerHTML="{{ 'GLOBAL.VOUCHER.VALUEX' | translate: { X: voucher.amount | agoraCurrency } }}"></p>
            <p class="voucher-code">{{ voucher.code }}</p>
            <div class="flex row copy-button">
              <div class="j-center">
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy" (click)="copyValue($event, voucher.code)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="j-center flex row">
                <p>{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
              </div>
            </div>
            <p
              *ngIf="!voucher.usedDate"
              class="title-m no-line-height expiration-date-label"
              innerHTML="{{ 'GLOBAL.VOUCHER.EXPIREATDATEX' | translate: { X: voucher.expirationDate | date: 'dd/MM/yyyy' } }}"></p>
            <div *ngIf="voucher.usedDate" class="flex row j-space-between used-date-label">
              <p>{{ 'GLOBAL.VOUCHER.USEDAT' | translate }}</p>
              <p class="date">{{ voucher.usedDate | date: 'dd/MM/yyyy' }}</p>
            </div>
          </div>
        </div>
        <div class="footer">
          <!--<div class="action-button">
            <div class="button">
              <button class="btn-icon btn-primary m-j-center">
                <svg viewBox="0 0 32 24" class="icon">
                  <use xlink:href="#icon-letter"></use>
                </svg>
              </button>
            </div>
            <p>{{'GLOBAL.ACTION.SEND' | translate}}</p>
          </div>-->
          <div class="action-button" *ngIf="!voucher.usedDate">
            <div class="button">
              <button class="btn-icon btn-primary m-j-center {{ voucher.usedDate ? 'disabled' : '' }}" (click)="download(voucher.code, !!voucher.usedDate)">
                <svg viewBox="0 0 28 29" class="icon">
                  <use xlink:href="#icon-download"></use>
                </svg>
              </button>
            </div>
            <p>{{ 'GLOBAL.ACTION.DOWNLOAD' | translate }}</p>
          </div>
        </div>
      </div>
    </app-carousel>
  </div>
</div>
