import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JWTService } from './jwt.service';
import { getApiUrlFromOrigin } from '../utils/filter.utils';

const BASE_URL = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private jwt: JWTService
  ) {}

  public getUserEmail() {
    return this.jwt.getEmailFromToken();
  }

  public getUserSub() {
    return this.jwt.getSubFromToken();
  }

  postRequestNewPassword() {
    const userMail = this.getUserEmail();
    return this.http.post(environment.identity_url + 'api/user/forgot-password', { loginId: userMail, applicationId: '1ba11db4-a6ee-43d7-a896-a7dc27c81e8e' });
  }

  postChangeUserEmail(request) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const sub = this.getUserSub();
    return this.http.post(`${BASE_URL}/users/${sub}/updateUserEmail`, request, { headers: this.headers });
  }

  updateUserProfile(request) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', 'Bearer ' + this.jwt.getToken());

    const sub = this.getUserSub();
    return this.http.put(`${BASE_URL}/clients/${sub}/profile`, request, { headers: this.headers });
  }
}
