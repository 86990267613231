import { Component, computed, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JWTService } from 'src/app/core/services/jwt.service';
import { CookieService } from 'ngx-cookie-service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { getRootUrl } from '../../../core/utils/filter.utils';
import { ErrorService } from '../../../core/services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../core/services/translation.service';
import { ContextStore } from '../../../stores/context.store';
import { UserStore } from '../../../stores/user.store';
import { DemoService } from '../../../core/services/V2/demo.service';
import { DemoAttendeeResponse, DemoStatusObject, GetDemoResponse } from '@victoria-company/agora-client';
import { CartStore } from '../../../stores/cart.store';
import { CartService } from '../../../core/services/V2/cart.service';
import { ResourcesStore } from '../../../stores/resources.store';

@Component({
  selector: 'app-desolanding',
  templateUrl: './deso-landing.component.html',
  styleUrls: ['./deso-landing.component.scss'],
})
export class DesoLandingComponent implements OnInit {
  //V2
  public contextStore = inject(ContextStore);
  public resourcesStore = inject(ResourcesStore);
  public cartStore = inject(CartStore);
  public userStore = inject(UserStore);

  private demoService = inject(DemoService);
  private cartService = inject(CartService);

  public requestedDemo: WritableSignal<GetDemoResponse> = signal(null);
  public demoAttendee: WritableSignal<DemoAttendeeResponse> = signal(null);

  public isUserFromDemo = computed(() => {
    return this.userStore.isAuthenticated() && this.demoAttendee();
  });

  public isUserAuthenticated = computed(() => {
    return this.userStore.isAuthenticated();
  });
  public isLoaded = signal(false);
  public isOptin = signal(false);

  public demoCodeOrId = signal(undefined);

  //V1
  locale: string;
  accept = false;
  // isAuthenticated = false;

  registrationDone = false;
  goToDemo = false;

  clientData: any;

  demoCode: string;
  delegateId: string;
  lang: string;

  constructor(
    private route: ActivatedRoute,
    private cookie: CookieService,
    public jwtService: JWTService,
    private trigger: TriggerService,
    private storage: StorageService,
    public dataServices: GetDatasService,
    public error: ErrorService,
    public translateConfig: TranslationService,
    public translate: TranslateService
  ) {
    effect(
      async () => {
        if (this.cartStore.isLoaded() && this.demoCodeOrId() && this.requestedDemo()) {
          await this.addOrCreateUserCartToDemo();
          if (!(await this.doRedirectUserToDemoIfNeeded())) this.isLoaded.set(true);
        }
      },
      { allowSignalWrites: true }
    );
  }

  async ngOnInit() {
    this.demoCodeOrId.set(this.route.snapshot.params.demoCode);

    const demo = await this.demoService.getDemoByCodeOrId(this.demoCodeOrId());
    this.requestedDemo.set(demo);

    if (this.isUserAuthenticated()) {
      const attendee = await this.getDemoAttendee();
      this.demoAttendee.set(attendee);
    }
  }

  goToEshop() {
    window.location.href = getRootUrl(this.contextStore.locale(), this.contextStore.region());
  }

  authenticate() {
    this.storage.redirectTo = `/${this.contextStore.locale()}/demo/${this.route.snapshot.params?.delegateId}/${this.route.snapshot.params?.demoCode}/${this.route.snapshot.params?.hostessName ?? ''}`;
    console.log('User will be redirected after authentication to : ', this.storage.redirectTo);

    this.trigger.requestLogin();
  }

  changeOptIn() {
    this.isOptin.set(!this.isOptin());
  }

  async joinDemo() {
    if (this.userStore.isAuthenticated() && !this.demoAttendee()) {
      await this.demoService.joinDemo(this.demoCodeOrId());

      this.demoAttendee.set(await this.getDemoAttendee());
      await this.doRedirectUserToDemoIfNeeded();
    }
  }

  getRemainingDays(date: Date) {
    const currentDate = new Date();
    const dateSent = new Date(date);
    const remainingDays =
      (Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24) < 0
        ? 0
        : (Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24);

    return Math.floor(remainingDays);
  }

  getSiteUrl() {
    const region = this.cookie.get('active_language');
    return getRootUrl(this.cookie.get('v_locale'), region);
  }

  navigateUrl(url: string) {
    window.location.href = url;
  }

  private async getDemoAttendee() {
    return this.demoService.getConnectedDemoAttendee(this.demoCodeOrId());
  }

  private async addOrCreateUserCartToDemo() {
    if (this.userStore.isAuthenticated() && this.requestedDemo()?.demo?.status != 'Closed') {
      if (this.cartStore.cart() && this.cartStore.cart().demoId != this.requestedDemo()?.demo?.id) {
        return this.cartService.affectCartToDemo(this.cartStore.cart().id, this.demoCodeOrId());
      } else if (!this.cartStore.cart()) {
        return this.cartStore.createCart(this.requestedDemo()?.demo?.id);
      }
    }
  }

  private async doRedirectUserToDemoIfNeeded() {
    if (this.isUserFromDemo() && this.requestedDemo()?.demo?.status == 'Opened') {
      window.location.href = `${window.origin}/${this.contextStore.locale()}/myvictoria/demo/${this.requestedDemo()?.demo.code}`;
      return true;
    } else return false;
  }

  protected readonly DemoStatusObject = DemoStatusObject;
}
