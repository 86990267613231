<ng-container *ngIf="resourcesStore.isLoaded() && cartStore.isLoaded()">
  <div class="cart-content flex j-center ai-start" data-testId="cart-block">
    <div class="cart-articles flex column gap-24">
      <!--    <div class="order-paid-toDelegate flex column j-start ai-center ac-center" *ngIf="cartState.cart?.flowStatuses?.payment?.status == 11">-->
      <div class="order-paid-toDelegate flex column j-start ai-center ac-center" *ngIf="false">
        <h1 class="canela center">{{ resourcesStore.i18n().resources.checkoutConfirmation.secondTitle }}</h1>
        <p class="center">
          {{ 'ORDER.VALIDATION.CLIENT.TODELEGATE' | translate }}
        </p>
      </div>
      <div class="bonus-area-container" *ngIf="cartStore.cart()?.isHostessCart && cartStore.cart()?.hostessBonus > 0">
        <div class="bonus-area">
          <span class="title">{{ 'CART.BONUS.CONGRATULATION' | translate }}</span>
          <span>{{ 'CART.BONUS.AMOUNT' | translate }}</span>
          <span class="amount">{{ cartStore.cart()?.hostessBonus | agoraCurrency }}</span>
        </div>
      </div>
      <div class="gift-area-container" *ngIf="cartStore.cart()?.isHostessCart">
        <div class="gift-area">
          <app-hostess-gift-cards-container
            *ngIf="this.cartStore.hostessGifts()?.giftsForCategories.length"
            (requestCardOnClickBehavior)="openSelectHostessGiftModal($event)"
            [hostessGifts]="this.cartStore.hostessGifts()"></app-hostess-gift-cards-container>
          <p *ngIf="!this.cartStore.hostessGifts()?.giftsForCategories.length">{{ 'DEMO.GIFTS.NOGIFTS' | translate }}</p>
        </div>
      </div>
      <div class="cart-with-variants-warning flex column ai-center ac-center" *ngIf="cartHasMultiVariants() && cartStore.checkoutType() == CheckoutType.DEMO">
        <p>{{ 'CART.MULTIVARIANT.WARNING' | translate }}</p>
      </div>
      <app-cart-articles
        [resources]="resourcesStore.i18n().resources.shoppingBag"
        (requestRedirectUserToCatalog)="goToCollection()"
        (setCartHasMultiVariants)="setHasMultiVariants($event)"></app-cart-articles>
    </div>

    <div class="cart-recap">
      <app-cart-recap-info
        [resources]="resourcesStore.i18n().resources"
        (requestOpenDeliveryInfoModal)="openDeliveryInfoModal()"
        (requestCartDelegateApproval)="requestDelegateApproval()"
        (requestRedirectUserToCatalog)="goToCollection()"></app-cart-recap-info>
    </div>
  </div>
</ng-container>
<!--<app-modal *ngIf="resources" [id]="'detailsModal'" [title]="resources.popupShippingOptions.mainTitle" template="info"></app-modal>-->
<app-shipping-info-modal [isOpened]="cartStore.deliveryInfoModal().isOpened" (requestCloseModal)="closeDeliveryInfoModal()"></app-shipping-info-modal>
<app-modal *ngIf="resourcesStore.isLoaded()" [id]="'codeModal'" [title]="resourcesStore.i18n().resources.shoppingBag.birthdayLabel" template="promo"></app-modal>
<app-select-hostess-gift-modal
  *ngIf="this.cartStore.hostessGifts()"
  [opened]="selectHostessGiftModalOpened"
  [clickedCategory]="clickedGiftCard"
  (requestCloseModal)="closeSelectHostessGiftModal()"
  (requestSaveSelectedGift)="saveHostessGift($event)"
  [hostessGifts]="this.cartStore.hostessGifts()"></app-select-hostess-gift-modal>
