import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataForm } from 'src/app/core/models/dataForm.model';
import { FormsService } from 'src/app/core/services/forms.service';
import { GetDatasService } from '../../../../../../core/services/get-datas.service';
import { Client } from '../../../../../../core/models/client.model';
import { UpdateClientProfileDto } from 'src/app/core/dto/updateClientProfileDto.model';
import { UserService } from 'src/app/core/services/user.service';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { Alert } from 'src/app/core/models/alert.model';
import { AlertTypes } from 'src/app/core/enums/alert-type.enum';
import { AlertService } from 'src/app/core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/core/services/modal.service';
@Component({
  selector: 'app-mydatas',
  templateUrl: './mydatas.component.html',
  styleUrls: ['./mydatas.component.scss'],
})
export class MydatasComponent implements OnInit {
  generalForm: UntypedFormGroup;
  updateProfileStatus: number = 0;
  operationPending: boolean = false;
  resources: any;

  currentActionTypeId = 0;

  public get form() {
    return this.generalForm?.controls;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public fs: FormsService,
    private getDatasService: GetDatasService,
    private userService: UserService,
    private translate: TranslationService,
    private trad: TranslateService,
    private alert: AlertService,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.generalForm = this.fs.getDataForms(this.fb, new DataForm());
    this.getDatasService.clientData$.subscribe(data => {
      if (data) this.setFormDatas(data);
    });
    this.translate.localesLoaded.subscribe(res => {
      if (res == true) {
        this.resources = this.translate.getJsonLocales()?.resources;
      }
    });
  }

  private setFormDatas(currentClient: Client) {
    this.generalForm.patchValue(currentClient);
    if (currentClient.birthDate) {
      const birthDate = currentClient.birthDate.toString().split('-');
      this.generalForm.controls.birthDate.setValue(`${birthDate[2].split('T')[0]}-${birthDate[1]}-${birthDate[0]}`);
    }

    this.generalForm.controls.gender.setValue(currentClient.genderId);

    const defaultAddress = currentClient.addresses.find(add => add.isDefault === true);
    this.generalForm.patchValue(defaultAddress);

    // const country = currentClient.locale.split('-')[1].toUpperCase();

    this.generalForm.controls.langage.setValue(currentClient.locale);

    this.generalForm.controls.country.setValue(currentClient.country);
    this.generalForm.controls.defaultAddressId.setValue(currentClient.defaultAddressId);
  }

  public update() {
    this.updateProfileStatus = 0;

    if (this.generalForm.valid) {
      this.operationPending = true;
      let dto = new UpdateClientProfileDto();
      dto.firstName = this.generalForm.get('firstName').value;
      dto.lastName = this.generalForm.get('lastName').value;
      dto.address1 = this.generalForm.get('address1').value;
      dto.address2 = this.generalForm.get('address2').value;
      dto.city = this.generalForm.get('city').value;
      dto.zipCode = this.generalForm.get('zipCode').value;
      dto.country = this.generalForm.get('country').value?.toUpperCase();
      dto.genderId = this.generalForm.get('gender').value;
      dto.locale = this.generalForm.get('langage').value;
      dto.mobile = this.generalForm.get('mobile').value;
      dto.phone = this.generalForm.get('phone').value;
      dto.defaultAddressId = this.generalForm.get('defaultAddressId').value;

      this.userService.updateUserProfile(dto).subscribe(
        (res: any) => {
          if (res.data) {
            this.getDatasService.updateClientDataInternaly(res.data);

            let alertData = new Alert();
            alertData.message = this.trad.instant('ALERT.PROFILE.UPDATED');
            alertData.type = AlertTypes.SUCCESS;

            this.alert.raiseAlert(alertData);
          }

          this.operationPending = false;
        },
        err => {
          this.updateProfileStatus = 1;
          this.operationPending = false;
          let alertData = new Alert();
          alertData.message = this.trad.instant('ALERT.ERROR.GLOBAL');
          alertData.type = AlertTypes.DANGER;
          alertData.icon = '#icon-warning';

          this.alert.raiseAlert(alertData);

          this.operationPending = false;
        }
      );
    } else this.fs.checkFormAndDisplayError(this.generalForm);
  }

  openBirthDateModal() {
    this.currentActionTypeId = 13;
    this.modal.open('#myDatasBirthDateModal');
  }

  updateBirthDate(value: Date) {
    let strDate;
    if (value) {
      strDate = value.getDate() >= 10 ? value.getDate() : '0' + value.getDate();
      strDate += '-' + (value.getMonth() + 1 >= 10 ? (value.getMonth() + 1).toString() : '0' + (value.getMonth() + 1).toString());
      strDate += '-' + value.getFullYear().toString();
    }

    this.generalForm.controls.birthDate.setValue(strDate);
  }
}
