import { Component, inject, OnInit } from '@angular/core';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CartResponse, DemoResponse, PaymentResponse, PaymentResponseStatusObject, PaymentTypeObject } from '@victoria-company/agora-client';
import { CartService } from '../../../../core/services/V2/cart.service';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { PaymentsService } from '../../../../core/services/V2/payments.service';
import { DemoService } from '../../../../core/services/V2/demo.service';

const MAX_OPEN_REQUEST = 5;
@Component({
  selector: 'app-commande-confirmation',
  templateUrl: './commande-confirmation.component.html',
  styleUrls: ['./commande-confirmation.component.scss'],
})
export class CommandeConfirmationComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);

  readonly paymentsService = inject(PaymentsService);
  readonly demoService = inject(DemoService);

  private openRequest = 0;

  constructor(
    public screen: ScreenService,
    private router: Router,
    private activated: ActivatedRoute,
    private cartService: CartService
  ) {}

  public cart: CartResponse;
  public demo: DemoResponse;
  public payment: PaymentResponse;

  async ngOnInit() {
    const lyfPayStatus = this.activated.snapshot.queryParams.status ?? '';
    const params = this.activated.snapshot.params;

    if (params.cartId) {
      const cart = await this.cartService.getCartByCartId(params.cartId);

      if (cart) {
        this.cart = cart;

        //When cart is related to a demoId, Check if the cart's user is member of the demo getting the demo
        if (this.cart.demoId) {
          const demoResponse = await this.demoService.getDemoByCodeOrId(this.cart.demoId);
          if (demoResponse && demoResponse.roles.length) this.demo = demoResponse.demo;
        }

        const sortedPayments =
          this.cart.cartPayments?.sort((a, b) => {
            if (a.creationDate > b.creationDate) return -1;
            else return 0;
          }) ?? [];

        if (sortedPayments.length && sortedPayments[0]?.result == 'Open' && lyfPayStatus == 'canceled') {
          //Handle LyfPayPayment update
          await this.paymentsService.cancelPayment(sortedPayments[0]?.paymentId);
          return await this.handlingPayment(sortedPayments[0]?.paymentId);
        } else if (sortedPayments.length && sortedPayments[0]?.paymentId) return await this.handlingPayment(sortedPayments[0]?.paymentId);
      }
    } else return this.router.navigate([`/${this.contextStore.locale()}/public/checkout`]);
  }

  goToCart() {
    return this.router.navigate([`/${this.contextStore.locale()}/public/cart`]);
  }

  private async handlingPayment(paymentId: string) {
    this.payment = await this.cartService.getCartPayment(paymentId);
    if (!this.payment) return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${PaymentResponseStatusObject.Unknown}`);
    switch (this.payment.status) {
      case PaymentResponseStatusObject.Open:
        if (this.payment.type == PaymentTypeObject.HandledByDelegate) return;
        else if (this.openRequest >= MAX_OPEN_REQUEST) {
          return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${PaymentResponseStatusObject.Unknown}`);
        } else {
          this.openRequest++;
          return setTimeout(async () => await this.handlingPayment(this.payment.id), 1250);
        }
      case PaymentResponseStatusObject.Pending:
        return setTimeout(async () => await this.handlingPayment(this.payment.id), 1250);
      case PaymentResponseStatusObject.Expired:
      case PaymentResponseStatusObject.Failed:
      case PaymentResponseStatusObject.Canceled:
      case PaymentResponseStatusObject.Unknown:
        return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${this.payment.status}`);
    }
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
}
