<div class="point-history-datas-container" *ngIf="actions">
  <p *ngIf="!actions.length && !(error.datatableError$ | async)" class="no-data-title">{{ 'DATATABLE.NODATA' | translate }}</p>
  <div class="error-box" *ngIf="error.datatableError$ | async">
    <div class="flex row ai-center">
      <svg viewBox="0 0 28 28" class="icon-warning">
        <use xlink:href="#icon-warning"></use>
      </svg>
      <div>
        <span>{{ 'ERROR.TITLE' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="d-only">
    <table class="data-table all-border border-separate border-radius no-line-height div-full-height" *ngIf="actions && actions.length">
      <tr *ngFor="let action of actions">
        <td class="actionIcon">
          <div>
            <p class="icon">
              <svg viewBox="0 0 32 32" *ngIf="action.clientActionTypeId !== 200 && action.clientActionTypeId !== 201">
                <use [attr.xlink:href]="'#' + clientActionTypeIcon[action.clientActionTypeId]"></use>
              </svg>
              <svg viewBox="0 0 36 36" *ngIf="action.clientActionTypeId === 200 || action.clientActionTypeId === 201">
                <use [attr.xlink:href]="'#' + clientActionTypeIcon[action.clientActionTypeId]"></use>
              </svg>
            </p>
          </div>
        </td>
        <td class="actionDate">
          <div>
            <p class="td-bold no-wrap">{{ action.actionDate | date: 'dd/MM/yyyy' }}</p>
          </div>
        </td>
        <td class="actionType">
          <div [ngSwitch]="action.clientActionTypeId">
            <div *ngSwitchCase="1">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.1' | translate }}</p>
              <p>{{ action.label }}</p>
            </div>
            <div *ngSwitchCase="20" class="bottom">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.20' | translate: { X: action.voucherValue | agoraCurrency } }}</p>
              <div class="flex code-button-line">
                <p class="flex ai-center">{{ action.voucherCode }}</p>
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="action.voucherCode">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy flex" (click)="copyValue($event, action.voucherCode)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="22" class="bottom">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.22' | translate }}</p>
              <div class="flex code-button-line">
                <p class="flex ai-center">{{ action.voucherCode }}</p>
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="action.voucherCode">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy flex" (click)="copyValue($event, action.voucherCode)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="200" class="bottom">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <div class="flex">
                <p class="bold-span" [innerHtml]="'ENCODING.TABLE.ACTION.STATUSCHANGED' | translate: { X: action.pointAfter, Y: 'CLIENT.STATUS' + action.statusIdAfter | translate | uppercase }"></p>
              </div>
            </div>
            <div *ngSwitchCase="201" class="bottom">
              <p class="td-bold no-wrap">{{ 'GLOBAL.OOPS' | translate }}</p>
              <div class="flex">
                <p class="bold-span" [innerHtml]="'ACTIONLOGS.LABEL.201' | translate: { X: 'CLIENT.STATUS' + action.statusIdAfter | translate | uppercase }"></p>
              </div>
            </div>
            <div *ngSwitchCase="21">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.21' | translate }}</p>
              <div class="flex copy-code">
                <p>{{ action.voucherCode }}</p>
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="action.voucherCode">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="303">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.303' | translate }}</p>
              <div class="flex copy-code">
                <p>{{ action.voucherCode }}</p>
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="action.voucherCode">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="304">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.304' | translate }}</p>
              <div class="flex copy-code">
                <p>{{ action.voucherCode }}</p>
                <div class="icon-container flex j-end">
                  <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="action.voucherCode">
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                  <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                    <svg viewBox="0 0 32 32" class="icon peach">
                      <use xlink:href="#icon-copy"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="10">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <p>{{ 'ENCODING.TABLE.ACTION.SOCIALX' | translate: { X: 'Instagram' } }}</p>
            </div>
            <div *ngSwitchCase="11">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <p>{{ 'ENCODING.TABLE.ACTION.SOCIALX' | translate: { X: 'Facebook' } }}</p>
            </div>
            <div *ngSwitchCase="12">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <p>{{ 'ENCODING.TABLE.ACTION.NEWSLETTER' | translate }}</p>
            </div>
            <div *ngSwitchCase="13">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.13' | translate }}</p>
            </div>
            <div *ngSwitchCase="80">
              <p class="td-bold no-wrap">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.80' | translate: { X: action.pointToAdd } }}</p>
            </div>
            <div *ngSwitchCase="81">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.LABEL.81' | translate }}</p>
              <p>{{ action.label }}</p>
            </div>
            <div *ngSwitchCase="90">
              <p class="td-bold no-wrap">{{ 'GLOBAL.OOPS' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.90' | translate: { X: action.pointToAdd } }}</p>
            </div>
            <div *ngSwitchCase="91">
              <p class="td-bold no-wrap">{{ 'GLOBAL.OOPS' | translate }}</p>
              <p class="no-wrap">{{ 'ACTIONLOGS.LABEL.91' | translate: { X: action.voucherCode } }}</p>
            </div>
            <div *ngSwitchCase="23">
              <p class="td-bold no-wrap">{{ 'GLOBAL.FORYOURINFORMATION' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.23' | translate: { X: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
            </div>
            <div *ngSwitchCase="220">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.DESCRIPTION.220' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.220' | translate: { X: Math.abs(action.pointToAdd) } }}</p>
            </div>
            <div *ngSwitchCase="221">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.DESCRIPTION.221' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.221' | translate: { X: Math.abs(action.pointToAdd) } }}</p>
            </div>
            <div *ngSwitchCase="222">
              <p class="td-bold no-wrap">{{ 'ACTIONLOGS.DESCRIPTION.222' | translate }}</p>
              <p>{{ 'ACTIONLOGS.LABEL.222' | translate: { X: Math.abs(action.pointToAdd), y: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
            </div>
          </div>
        </td>
        <td class="div-text-bottom-td actionDiamsCode">
          <div>
            <p class="bottom" *ngIf="action.diamsCode">{{ 'ENCODING.TABLE.ACTION.DIAMSX' | translate: { X: action.diamsCode } }}</p>
          </div>
        </td>
        <td class="actionAmount">
          <div>
            <div [ngSwitch]="action.clientActionTypeId">
              <p *ngSwitchCase="1" class="td-peach td-bold no-wrap">+ {{ action.walletToAdd | agoraCurrency }}</p>
              <p *ngSwitchCase="21" class="td-peach td-bold no-wrap">- {{ action.walletToAdd | agoraCurrency }}</p>
              <p *ngSwitchCase="22" class="td-peach td-bold no-wrap space-nowrap">+ {{ action.voucherValue | agoraCurrency }}</p>
              <p *ngSwitchCase="23" class="td-peach td-bold no-wrap space-nowrap">{{ action.walletToAdd | agoraCurrency }}</p>
              <p *ngSwitchCase="222" class="td-peach td-bold no-wrap space-nowrap">+ {{ action.walletToAdd | agoraCurrency }}</p>
            </div>
          </div>
        </td>
        <td class="actionPoint">
          <div *ngIf="action.pointToAdd">
            <p *ngIf="action.clientActionTypeId !== 90" class="td-peach td-bold no-wrap">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
            <p *ngIf="action.clientActionTypeId === 90" class="td-peach td-bold no-wrap">- {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
            <!-- <p *ngIf="action.endValidityDate">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="m-only">
    <app-carousel (endScrollEvent)="triggerCall.emit()">
      <div class="card" *ngFor="let action of actions">
        <div class="header">
          <div class="flex row ai-center">
            <svg viewBox="0 0 32 32" class="icon height100">
              <use [attr.xlink:href]="'#' + clientActionTypeIcon[action.clientActionTypeId]"></use>
            </svg>
            &nbsp; {{ action.actionDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="body">
          <div class="container">
            <div [ngSwitch]="action.clientActionTypeId">
              <div *ngSwitchCase="1">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.1' | translate }}</p>
                  <p class="encoding-action-label">{{ action.label }}</p>
                </div>
                <div>
                  <p class="encoding-action-code-diams" *ngIf="action.diamsCode">
                    {{ 'ENCODING.TABLE.ACTION.DIAMSX' | translate: { X: '' } }}<span class="right">{{ action.diamsCode }}</span>
                  </p>
                  <p class="encoding-action-value">+ {{ action.walletToAdd | agoraCurrency }}</p>
                </div>
              </div>
              <div *ngSwitchCase="21">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.21' | translate }}</p>
                  <div class="flex copy-code">
                    <p>{{ action.voucherCode }}</p>
                    <div class="icon-container flex j-end">
                      <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="'m_' + action.voucherCode">
                        <use xlink:href="#icon-checkmark"></use>
                      </svg>
                      <button class="btn-icon btn-copy" (click)="copyValue($event, action.voucherCode)">
                        <svg viewBox="0 0 32 32" class="icon peach">
                          <use xlink:href="#icon-copy"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="200">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <div class="flex">
                    <p
                      class="bold-span"
                      [innerHtml]="'ENCODING.TABLE.ACTION.STATUSCHANGED' | translate: { X: action.pointAfter, Y: 'CLIENT.STATUS' + action.statusIdAfter | translate | uppercase }"></p>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="201">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.OOPS' | translate }}</p>
                  <div class="flex">
                    <p class="bold-span" [innerHtml]="'ACTIONLOGS.LABEL.201' | translate: { X: 'CLIENT.STATUS' + action.statusIdAfter | translate | uppercase }"></p>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="20">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.20' | translate: { X: action.voucherValue | agoraCurrency } }}</p>
                  <div class="copy-button">
                    <p class="action-code">{{ action.voucherCode }}</p>
                    <div class="copy-code flex row ai-center">
                      <div class="icon-container flex ai-center">
                        <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="'m_' + action.voucherCode">
                          <use xlink:href="#icon-checkmark"></use>
                        </svg>
                        <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                          <svg viewBox="0 0 32 32" class="icon peach">
                            <use xlink:href="#icon-copy"></use>
                          </svg>
                        </button>
                      </div>
                      <div class="flex ai-center">
                        <p class="copy-button-label">{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="22">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.22' | translate }}</p>
                  <div class="copy-button">
                    <p class="action-code">{{ action.voucherCode }}</p>
                    <div class="flex column">
                      <div class="copy-code flex ai-center">
                        <div class="icon-container flex j-end">
                          <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="'m_' + action.voucherCode">
                            <use xlink:href="#icon-checkmark"></use>
                          </svg>
                          <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                            <svg viewBox="0 0 32 32" class="icon peach">
                              <use xlink:href="#icon-copy"></use>
                            </svg>
                          </button>
                        </div>
                        <div class="flex ai-center">
                          <p class="copy-button-label">{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                        </div>
                      </div>
                      <div class="ai-end">
                        <p class="encoding-action-value">+ {{ action.voucherValue | agoraCurrency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="303">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.303' | translate }}</p>
                  <div class="copy-button">
                    <p class="action-code">{{ action.voucherCode }}</p>
                    <div class="flex column">
                      <div class="copy-code flex ai-center">
                        <div class="icon-container flex j-end">
                          <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="'m_' + action.voucherCode">
                            <use xlink:href="#icon-checkmark"></use>
                          </svg>
                          <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                            <svg viewBox="0 0 32 32" class="icon peach">
                              <use xlink:href="#icon-copy"></use>
                            </svg>
                          </button>
                        </div>
                        <div class="flex ai-center">
                          <p class="copy-button-label">{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="304">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.304' | translate }}</p>
                  <div class="copy-button">
                    <p class="action-code">{{ action.voucherCode }}</p>
                    <div class="flex column">
                      <div class="copy-code flex ai-center">
                        <div class="icon-container flex j-end">
                          <svg viewBox="0 0 32 32" class="icon icon-copy-check" [id]="'m_' + action.voucherCode">
                            <use xlink:href="#icon-checkmark"></use>
                          </svg>
                          <button class="btn-copy" (click)="copyValue($event, action.voucherCode)">
                            <svg viewBox="0 0 32 32" class="icon peach">
                              <use xlink:href="#icon-copy"></use>
                            </svg>
                          </button>
                        </div>
                        <div class="flex ai-center">
                          <p class="copy-button-label">{{ 'GLOBAL.VOUCHER.COPYCODE' | translate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="10">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ENCODING.TABLE.ACTION.SOCIALX' | translate: { X: 'Instagram' } }}</p>
                </div>
              </div>
              <div *ngSwitchCase="11">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ENCODING.TABLE.ACTION.SOCIALX' | translate: { X: 'Facebook' } }}</p>
                </div>
              </div>
              <div *ngSwitchCase="12">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ENCODING.TABLE.ACTION.NEWSLETTER' | translate }}</p>
                </div>
              </div>
              <div *ngSwitchCase="13">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.13' | translate }}</p>
                </div>
              </div>
              <div *ngSwitchCase="80">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.CONGRATULATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.80' | translate: { X: action.pointToAdd } }}</p>
                </div>
              </div>
              <div *ngSwitchCase="81">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.LABEL.81' | translate }}</p>
                  <p class="encoding-action-label">{{ action.label }}</p>
                </div>
              </div>

              <div *ngSwitchCase="90">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.OOPS' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.90' | translate: { X: action.pointToAdd } }}</p>
                </div>
              </div>

              <div *ngSwitchCase="91">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.OOPS' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.91' | translate: { X: action.voucherCode } }}</p>
                </div>
              </div>

              <div *ngSwitchCase="23">
                <div class="stick">
                  <p class="encoding-action">{{ 'GLOBAL.FORYOURINFORMATION' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.23' | translate: { X: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
                </div>
                <div>
                  <p class="encoding-action-value">{{ action.walletToAdd | agoraCurrency }}</p>
                </div>
              </div>

              <div *ngSwitchCase="220">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.DESCRIPTION.220' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.220' | translate: { X: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
                </div>
              </div>

              <div *ngSwitchCase="221">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.DESCRIPTION.221' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.221' | translate: { X: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
                </div>
              </div>

              <div *ngSwitchCase="222">
                <div class="stick">
                  <p class="encoding-action">{{ 'ACTIONLOGS.DESCRIPTION.222' | translate }}</p>
                  <p class="encoding-action-label">{{ 'ACTIONLOGS.LABEL.222' | translate: { X: Math.abs(action.walletToAdd) | agoraCurrency } }}</p>
                </div>
                <div>
                  <p class="encoding-action-value">+ {{ action.walletToAdd | agoraCurrency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="container">
            <div [ngSwitch]="action.clientActionTypeId">
              <div *ngSwitchCase="1" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="10" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="11" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="12" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="13" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="80" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="81" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
                <!-- <p class="encoding-action-label">{{'GLOBAL.VOUCHER.EXPIREAT' | translate}} {{action.endValidityDate | date:'dd/MM/yyyy'}}</p> -->
              </div>
              <div *ngSwitchCase="90" class="line-height-zero">
                <p class="encoding-action-value">- {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
              </div>
              <div *ngSwitchCase="220" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
              </div>
              <div *ngSwitchCase="221" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
              </div>
              <div *ngSwitchCase="222" class="line-height-zero">
                <p class="encoding-action-value">+ {{ 'GLOBAL.XPOINTS' | translate: { Points: action.pointToAdd } }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-carousel>
  </div>
</div>
