<app-page-title mainTitle="{{ 'STATUS.TITLE' | translate }}" subTitle="{{ 'STATUS.SUBTITLE' | translate }}"> </app-page-title>

<div class="current-status-container" *ngIf="currentStatus">
  <div class="current-status-title">
    <p innerHtml="{{ 'STATUS.POINT.SUMMARY' | translate: { Status: 'CLIENT.STATUS' + currentStatus.statusId | translate, points: currentStatus.totalPoints } }}"></p>
  </div>

  <div class="current-status-content">
    <div class="card">
      <app-card
        class="flex flex-1"
        [cardType]="cardType.STATUS"
        [data]="currentStatus"
        [forecasts]="forecasts"
        [showStatusLink]="false"
        [showTooltip]="true"
        [footerInformationTooltipText]="'STATUS.TOOLTIP' | translate"
        [footerInformationTooltipPosition]="'right'"
        [paddingBottom]="!!currentStatus.hasAlertExpirationPoint"
        [dropPadding]="true"></app-card>
    </div>

    <div class="current-status-summary">
      <div class="current-status-summary-center">
        <div class="current-status-summary-title">
          <p innerHtml="{{ 'STATUS.POINT.SUMMARY' | translate: { Status: 'CLIENT.STATUS' + currentStatus.statusId | translate, points: currentStatus.totalPoints } }}"></p>
        </div>
        <div class="current-status-advantages">
          <span class="current-status-advantages-title">{{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }} </span>
          <ul class="current-status-advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS' + currentStatus.statusId | translate }}"></ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="status-list-container" *ngIf="currentStatus" [ngClass]="currentStatus.statusId === 4 ? 'd-only' : ''">
  <div class="container">
    <h1
      class="status-list-title"
      *ngIf="currentStatus.statusId < 4"
      innerHTML="{{ 'STATUS.TITLE.XPOINTSREMAININGTOSTATUSY' | translate: { X: getRemainingPoints(), Y: 'CLIENT.STATUS' + (currentStatus.statusId + 1) | translate } }}"></h1>
    <h1 class="status-list-title" *ngIf="currentStatus.statusId === 4" innerHTML="{{ 'STATUS.TITLE.STATUSMAXREACHED' | translate: { X: 'CLIENT.STATUS' + currentStatus.statusId | translate } }}"></h1>
    <div class="current-status-marker">
      <div class="marker">
        <svg viewBox="0 0 31 27" [ngClass]="currentStatus.statusId === 1 ? 'visible' : ''">
          <use xlink:href="#icon-triangle"></use>
        </svg>
      </div>
      <div class="marker">
        <svg viewBox="0 0 31 27" [ngClass]="currentStatus.statusId === 2 ? 'visible' : ''">
          <use xlink:href="#icon-triangle"></use>
        </svg>
      </div>
      <div class="marker">
        <svg viewBox="0 0 31 27" [ngClass]="currentStatus.statusId === 3 ? 'visible' : ''">
          <use xlink:href="#icon-triangle"></use>
        </svg>
      </div>
      <div class="marker">
        <svg viewBox="0 0 31 27" [ngClass]="currentStatus.statusId === 4 ? 'visible' : ''">
          <use xlink:href="#icon-triangle"></use>
        </svg>
      </div>
    </div>

    <div class="status-list">
      <div class="status-list-card" [ngClass]="[currentStatus.statusId === 1 ? 'current-status' : '', currentStatus.statusId + 1 === 1 ? 'futur-status' : '']">
        <div class="card-title">
          <span class="status-text">{{ 'STATUS.CARD.TITLE' | translate }}</span>
          <span class="status-value">{{ 'CLIENT.STATUS1' | translate }}</span>
        </div>
        <div class="card-img-container">
          <img loading="lazy" src="../../../../../assets/images/business/statut-1.svg" />
        </div>
        <div class="card-info">
          <div class="info-sm">
            <p class="card-info-title">{{ 'STATUS.CARD.POINTS.GET' | translate }}</p>
            <div class="info-main card-info-points">{{ 'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 0, Y: 249 } }}</div>
          </div>
        </div>
        <div class="card-advantages-container">
          <div class="advantages-title center">
            {{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }}
          </div>
          <div class="advantages">
            <ul class="advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS1' | translate }}"></ul>
          </div>
        </div>
      </div>
      <div class="status-list-card" [ngClass]="[currentStatus.statusId === 2 ? 'current-status' : '', currentStatus.statusId + 1 === 2 ? 'futur-status' : '']">
        <div class="card-title">
          <span class="status-text">{{ 'STATUS.CARD.TITLE' | translate }}</span>
          <span class="status-value">{{ 'CLIENT.STATUS2' | translate }}</span>
        </div>
        <div class="card-img-container">
          <img loading="lazy" src="../../../../../assets/images/business/statut-2.svg" />
        </div>
        <div class="card-info">
          <div class="info-sm">
            <p class="card-info-title">{{ 'STATUS.CARD.POINTS.GET' | translate }}</p>
            <div class="info-main card-info-points">{{ 'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 250, Y: 499 } }}</div>
          </div>
        </div>
        <div class="card-advantages-container">
          <div class="advantages-title center">
            {{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }}
          </div>
          <div class="advantages">
            <ul class="advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS2' | translate }}"></ul>
          </div>
        </div>
      </div>
      <div class="status-list-card" [ngClass]="[currentStatus.statusId === 3 ? 'current-status' : '', currentStatus.statusId + 1 === 3 ? 'futur-status' : '']">
        <div class="card-title">
          <span class="status-text">{{ 'STATUS.CARD.TITLE' | translate }}</span>
          <span class="status-value">{{ 'CLIENT.STATUS3' | translate }}</span>
        </div>
        <div class="card-img-container">
          <img loading="lazy" src="../../../../../assets/images/business/statut-3.svg" />
        </div>
        <div class="card-info">
          <div class="info-sm">
            <p class="card-info-title">{{ 'STATUS.CARD.POINTS.GET' | translate }}</p>
            <div class="info-main card-info-points">{{ 'STATUS.CARD.POINTS.RANGEXY' | translate: { X: 500, Y: 749 } }}</div>
          </div>
        </div>
        <div class="card-advantages-container">
          <div class="advantages-title center">
            {{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }}
          </div>
          <div class="advantages">
            <ul class="advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS3' | translate }}"></ul>
          </div>
        </div>
      </div>
      <div class="status-list-card" [ngClass]="[currentStatus.statusId === 4 ? 'current-status' : '', currentStatus.statusId + 1 === 4 ? 'futur-status' : '']">
        <div class="card-title">
          <span class="status-text">{{ 'STATUS.CARD.TITLE' | translate }}</span>
          <span class="status-value">{{ 'CLIENT.STATUS4' | translate }}</span>
        </div>
        <div class="card-img-container">
          <img loading="lazy" src="../../../../../assets/images/business/statut-4.svg" />
        </div>
        <div class="card-info">
          <div class="info-sm">
            <p class="card-info-title">{{ 'STATUS.CARD.POINTS.GET' | translate }}</p>
            <div class="info-main card-info-points">{{ 'STATUS.CARD.POINTS.RANGEMAXX' | translate: { X: 749 } }}</div>
          </div>
        </div>
        <div class="card-advantages-container">
          <div class="advantages-title center">
            {{ 'STATUS.CARD.AVANTAGES.TITLE' | translate }}
          </div>
          <div class="advantages">
            <ul class="advantages-list" innerHTML="{{ 'STATUS.CARD.AVANTAGES.STATUS4' | translate }}"></ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="status-tips-container" *ngIf="currentStatus">
  <div>
    <app-status-data-wrapper [currentStatus]="currentStatus" [clientActionsDone]="clientActionsDone"></app-status-data-wrapper>
  </div>
</div>
