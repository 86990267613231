import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from '../../../../core/services/translation.service';
import { ErrorService } from '../../../../core/services/error.service';
import { ModalService } from '../../../../core/services/modal.service';
import { StorageService } from '../../../../core/services/storage.service';
import { Resources1 } from '../../../../state/models/resources';
import { getProfilePictureOrDefault } from '../../../../core/utils/filter.utils';
import { CartResponse, DelegateResponse, UserProfileResponse } from '@victoria-company/agora-client';

@Component({
  selector: 'app-order-contact-box',
  templateUrl: './order-contact-box.component.html',
  styleUrls: ['./order-contact-box.component.scss'],
})
export class OrderContactBoxComponent implements OnInit {
  @Input() delegate: DelegateResponse;
  @Input() profile: UserProfileResponse;
  @Input() resources: Resources1;
  @Input() cart: CartResponse;

  @Input() demoCode: string;
  @Input() contextId: number;

  @Output() requestUpdateCartDelegate = new EventEmitter();
  @Output() requestOpenSearchDelegateModal = new EventEmitter();

  // resources: any;
  hasDemoCookie = false;

  constructor(
    // public fidelityService: FidelityService,
    // private orderService: OrderService,
    public modal: ModalService,
    private storage: StorageService,
    // private jwtService: JWTService,
    public translate: TranslationService,
    public error: ErrorService
  ) {
    // this.modal.triggerAction$.subscribe((data: ModalAction) => {
    //   if (data && data.actionName == 'validateDemoCode') {
    //     this.validateDemoCode(data.value);
    //   }
    //   if (data && data.actionName == 'searchDelegateBy') {
    //     this.searchDelegateBy(data.value);
    //   }
    //   if (data && data.actionName == 'selectDelegate') {
    //     this.changeDelegate(data.value);
    //   }
    // });
  }

  ngOnInit() {
    // this.translate.localesLoaded.subscribe(res => {
    //   if (res == true) {
    //     this.resources = this.translate.getJsonLocales()?.resources;
    //   }
    // });

    this.hasDemoCookie = this.storage.demoId != undefined;
  }

  // confirmDelegate() {
  //   this.orderService.putGuestOrder(
  //     {
  //       delegateId: this.delegate.delegateId,
  //       locale: this.translate.getCodeLangueFromUserLocale(
  //         this.storage.languageCode
  //       ),
  //       sub: this.jwtService.getSubFromToken(),
  //     },
  //     true,
  //     2
  //   );
  //   // this.changeStep.emit(2);
  // }

  // confirmDemoCode() {
  //   this.orderService.putGuestOrder(
  //     {
  //       demoCode: this.demoCode,
  //       delegateId: this.delegate.delegateId,
  //       locale: this.translate.getCodeLangueFromUserLocale(
  //         this.storage.languageCode
  //       ),
  //       sub: this.jwtService.getSubFromToken(),
  //     },
  //     true,
  //     2
  //   );
  //   // this.changeStep.emit(2);
  // }

  // private validateDemoCode(demoCode) {
  //   if (!demoCode || demoCode == 'undefined') {
  //     this.demoCode = undefined;
  //     this.modal.close('#orderSelectCode');
  //   } else {
  //     this.orderService
  //       .checkDemoCodeValidity(this.contextId, demoCode)
  //       .subscribe(
  //         res => {
  //           if (res.data && res.data.status == 1 && res.data.delegate != null) {
  //             this.demoCode = res.data.demoCode ?? res.data.demoId;
  //             this.fidelityService.updateDelegate(res.data.delegate);
  //             this.modal.close('#orderSelectCode');
  //             this.storage.demoCode = res.data.demoCode ?? res.data.demoId;
  //             this.updateDemoCode.emit(this.demoCode);
  //           }
  //         },
  //         err => {
  //           if (err.error && err.error.data) {
  //             this.modal.raiseError(err.error.data.status);
  //           }
  //         }
  //       );
  //   }
  // }

  public confirmDelegate() {
    this.requestUpdateCartDelegate.emit();
  }

  public openSearchDelegate() {
    this.requestOpenSearchDelegateModal.emit();
  }

  // private searchDelegateBy(form) {
  //   this.fidelityService
  //     .lookupForOtherDelegates(this.contextId, this.profile, form)
  //     .subscribe(
  //       res => {
  //         this.delegatesToSelect = res.data;
  //         this.refreshDelegatesToSelect.emit(this.delegatesToSelect);
  //         this.modal.open('#orderSelectDelegate');
  //       },
  //       err => {
  //         //In case of an error (mainly bad request, follow with no delegate found)
  //         this.delegatesToSelect = [];
  //         this.refreshDelegatesToSelect.emit(this.delegatesToSelect);
  //         this.modal.open('#orderSelectDelegate');
  //       }
  //     );
  // }

  // private changeDelegate(delegate) {
  //   this.fidelityService.updateDelegate(delegate);
  // }
  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
}
