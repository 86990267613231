<div class="select-gift-container" [class.scrollable]="isModal" *ngIf="hostessGifts?.giftsForCategories.length">
  <span class="title">
    @if (isDelegate) {
      {{ 'DEMO.MENU.HOSTESS.GIFTS' | translate }}
    } @else {
      {{ 'DEMO.GIFTS.CONGRATULATIONS' | translate }}
    }
  </span>
  <app-hostess-gift-cards-container
    (requestCardOnClickBehavior)="changeCategory($event)"
    [activeCategory]="selectedCategory"
    [hostessGifts]="hostessGifts"
    [locked]="giftsLocked"></app-hostess-gift-cards-container>
  <span class="caption">{{ 'DEMO.GIFTS.CLICK.BELOW' | translate }}</span>
  <div [class.scrollable]="isModal">
    <div class="gifts-list" [class.wide-display]="!isModal">
      <div class="gift-picture-container" *ngFor="let gift of getGiftsFromSelectedCategory()" (click)="selectGiftToSave(gift)">
        <svg *ngIf="isSelectedGift(gift)" viewBox="0 0 32 32" class="selected-gift icon">
          <use xlink:href="#checked-bullet"></use>
        </svg>
        <img [src]="getDataService.getProductImagesPathForHigh(gift.product.imageThumbnail)" />
        <div *ngIf="gift.missingTurnOver > 0" class="gift-unavailable flex center-all">
          <p>{{ 'DEMO.GIFTS.MISSINGTURNOVER' | translate: { X: gift.missingTurnOver | agoraCurrency } }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!hostessGifts?.giftsForCategories.length" class="select-gift-container">
  <p>{{ 'DEMO.GIFTS.NOGIFTS' | translate }}</p>
</div>
